import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {infoColor} from "../assets/jss/livability.js";


export default class Example extends PureComponent {
  
  constructor(props){
    super(props);

    this.state = {
        w: window.innerWidth*0.43, 
        h: window.innerHeight*0.7,  
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }
  
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({...this.state, 
      w: window.innerWidth*0.43, 
      h: window.innerHeight*0.7,  
    });
  }

  render() {

    const CustomizedLabel = (props) => {
        const {x, y, stroke, value} = props;        
         return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">{value}</text>
    };

    const score_param = this.props.indicator  !== "" ? this.props.indicator + 'score' : this.props.pillar + this.props.indicator + 'score';
    const rank_param = this.props.indicator  !== "" ? this.props.indicator + 'rank' : this.props.pillar +  this.props.indicator + 'rank';

    const selectedRanks = this.props.ranking ? this.props.ranking.filter(r => !r.unselected ) : [];


    const data = selectedRanks.map(r => {
          let temp = Object.assign({}, r);
          temp.Score = temp[score_param].toFixed(2);
          return temp;
    });

    data.sort(function(a, b) {
      return a[rank_param] - b[rank_param];
    });

    return (
      <div style={{height:'70vh'}}>
        {
          data.length > 0 ?
          <ResponsiveContainer width="100%" height={this.state.h}>
          <BarChart
            width="100%"
            height="100%"
            data={data}
            margin={{
              top: 5, right: 5, left: 50, bottom: 5,
            }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Score" type="number" ticks={[0, 20, 40, 60, 80, 100]}/>
            <YAxis dataKey="city" type="category"/>
            <Tooltip />
            <Legend label={<CustomizedLabel/>} />
            <Bar dataKey="Score" fill={infoColor[0]}  />
          </BarChart>
        </ResponsiveContainer>:
          <div>No city selected</div>
        }
        
      </div>
    );
  }
}
