const states = [
    {
        "name": "Andaman and Nicobar"
    },
    {
        "name": "Andhra Pradesh"
    },
    {
        "name": "Arunachal Pradesh"
    },
    {
        "name": "Assam"
    },
    {
        "name": "Bihar"
    },
    {
        "name": "Chandigarh"
    },
    {
        "name": "Chhattisgarh"
    },
    {
        "name": "Delhi"
    },
    {
        "name": "Goa"
    },
    {
        "name": "Gujarat"
    },
    {
        "name": "Haryana"
    },
    {
        "name": "Himachal Pradesh"
    },
    {
        "name": "Jammu and Kashmir"
    },
    {
        "name": "Jharkhand"
    },
    {
        "name": "Karnataka"
    },
    {
        "name": "Kerala"
    },
    {
        "name": "Ladakh"
    },
    {
        "name": "Lakshadweep"
    },
    {
        "name": "Madhya Pradesh"
    },
    {
        "name": "Maharashtra"
    },
    {
        "name": "Manipur"
    },
    {
        "name": "Meghalaya"
    },
    {
        "name": "Mizoram"
    },
    {
        "name": "Nagaland"
    },
    {
        "name": "Odisha"
    },
    {
        "name": "Puducherry"
    },
    {
        "name": "Punjab"
    },
    {
        "name": "Rajasthan"
    },
    {
        "name": "Sikkim"
    },
    {
        "name": "Tamil Nadu"
    },
    {
        "name": "Telangana"
    },
    {
        "name": "Dadra, Nagar Haveli, Daman and Diu"
    },
    {
        "name": "Tripura"
    },
    {
        "name": "Uttarakhand"
    },
    {
        "name": "Uttar Pradesh"
    },
    {
        "name": "West Bengal"
    }
];

export default states;