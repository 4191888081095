import React from 'react';
import {Component} from 'react';
import ReactMapGL, {Marker,  Popup} from 'react-map-gl';
import Hidden from "@material-ui/core/Hidden";

import CityPin from './CityPin.js';
import {warningColor,dangerColor,successColor} from "../assets/jss/livability.js";


class Map extends Component {
  constructor(props){
    super(props);
    this.state = { viewport: this.props.viewport, city: "" };
  }

  _updateViewport = viewport => {
    this.setState({viewport});
  };

  _renderPopup() {
    const {city} = this.state;

    return (city !== "") && (
      <Popup tipSize={5}
        anchor="top"
        longitude={parseFloat(city.long)}
        latitude={parseFloat(city.lat)}
        closeOnClick={false}
        onClose={() => this.setState({...this.state, city: ""})} >
          <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
            <div>City: {city.city}</div>
            <div>Rank: {city[this.props.indicator !== "" ? this.props.indicator + 'rank' : this.props.pillar + this.props.indicator + 'rank']}</div>
            <div>Score: {city[this.props.indicator !== "" ? this.props.indicator + 'score' : this.props.pillar + this.props.indicator + 'score'].toFixed(2)}</div> 
          </div>
      </Popup>
    );
  }

  render(){
    const score_param = this.props.indicator !== "" ? this.props.indicator + 'score' : this.props.pillar + this.props.indicator + 'score';
    //const rank_param = this.props.pillar +  this.props.indicator + 'rank';

    const selectedRanks = this.props.ranking ? this.props.ranking.filter(r => !r.unselected ) : [];

    const markers = selectedRanks.map((city) => {
      return (
                <Marker 
                  key={city.id} 
                  longitude={parseFloat(city.long)} 
                  latitude={parseFloat(city.lat)}
                  captureDrag={false}
                  captureDoubleClick={false}
                >
                  <CityPin 
                    size={20} 
                    clr={city[score_param] > 50 ? successColor[0] : city[score_param] > 30 ? warningColor[0] : dangerColor[0]} 
                    onClick={() => this.setState({...this.state, city: city})}
                  />
                </Marker>
        ) 
    });
      
      return (
        <div style={{height: '70vh'}}> 
         <ReactMapGL 
            {...this.state.viewport}
            mapboxApiAccessToken="pk.eyJ1IjoicmFhbWFtIiwiYSI6ImNra21ubDhrdDBranEydm5zeDJ6dXB0YmIifQ.GqBEHX5iQdnh2Qndwum5JQ"
            mapStyle="mapbox://styles/raamam/ckkop8uzm051b17pg63hvlhf7"
            onViewportChange={this._updateViewport}
            scrollZoom={false}
        >
          {markers}
          {this._renderPopup()}

          <Hidden mdDown implementation="css">
            <div style={{position: 'absolute', top: 5, right: 5, display:'flex', flexDirection:'column', backgroundColor:'white', padding: '5px 10px', }}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'space-between'}}>
                <div style={{height: '20px', width: '20px', backgroundColor: dangerColor[0]}} /><div style={{paddingLeft:'10px'}}>Score &lt; 30</div>
              </div>

              <div style={{display:'flex', flexDirection:'row', alignItems:'space-between'}}>
                <div style={{height: '20px', width: '20px', backgroundColor: warningColor[0]}} /><div style={{paddingLeft:'10px'}}>Score between 30 &amp; 50</div>
              </div>

              <div style={{display:'flex', flexDirection:'row', alignItems:'space-between'}}>
                <div style={{height: '20px', width: '20px', backgroundColor: successColor[0]}} /><div style={{paddingLeft:'10px'}}>Score &gt; 50</div>
              </div>
              
            </div>
          </Hidden>
        </ReactMapGL>     
      </div>

      );
    }
  }


export default Map;