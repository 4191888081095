const eol_stats = {
    count: 111,
    '': 53.51,
    'qol_': 51.38,
    'ea_': 13.17,
    's_': 53.63,
    'cp_': 76.08
};

const mpi_stats = {
    count: 111,
    '': 43.13,
    's_': 52.13,
    'f_': 51.11,
    't_': 31.44,
    'p_': 34.03,
    'g_': 42.83
}

export  {eol_stats, mpi_stats} ;