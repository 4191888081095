import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'rgb(112, 112, 112)',
      color: 'white',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: 'rgb(112, 112, 112)',
    },
}))(Tooltip);

export default function Clicktip(props){
    return (
        <ClickAwayListener onClickAway={props.close}>
        <HtmlTooltip
            PopperProps={{
                disablePortal: true,
            }}
            
            title={
            <React.Fragment>
                {props.title}
            </React.Fragment>
            }

            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={props.open}
            arrow
            placement="top"
        >
            {props.children}
        </HtmlTooltip>
        </ClickAwayListener>
    )
}
