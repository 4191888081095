
const pillars = { 
    "eol" : [{value: "Quality of life", key: "qol_"}, {value: "Economic ability" , key: "ea_"} , {value: "Sustainability" , key: "s_"}, { value: "Citizen perception" , key: "cp_"} ],  
    "mpi" : [{value: "Services" , key: "s_"} , { value: "Finance" , key: "f_"}, {value: "Technology" , key:  "t_"} , {value: "Planning" , key: "p_"} , { value: "Governance" , key: "g_"} ]
};

const mpi_pillar_indicators = {
    "s_" : [{ "key" : "s_1_" ,"value" : "Education"}, { "key" : "s_2_" ,"value" : "Health"}, { "key" : "s_3_" ,"value" : "Water & Wastewater"}, { "key" : "s_4_" ,"value" : "SWM & Sanitation"}, { "key" : "s_5_" ,"value" : "Registration & Permits"}, { "key" : "s_6_" ,"value" : "Infrastructure"}],
    "f_" : [{ "key" : "f_1_" ,"value" : "Revenue Management"}, { "key" : "f_2_" ,"value" : "Expenditure Management"}, { "key" : "f_3_" ,"value" : "Fiscal Responsibility"}, { "key" : "f_4_" ,"value" : "Fiscal Decentralisation"} ],
    "p_" : [{ "key" : "p_1_" ,"value" : "Plan Preparation"}, { "key" : "p_2_" ,"value" : "Plan Implementation"}, { "key" : "p_3_" ,"value" : "Plan Enforcement"} ],
    "t_" : [{ "key" : "t_2_" ,"value" : "Digital Governance"}, { "key" : "t_1_" ,"value" : "Digital Access"}, { "key" : "t_3_" ,"value" : "Digital Literacy"} ],
    "g_" : [{ "key" : "g_1_" ,"value" : "Transparency & Accountability"}, { "key" : "g_2_" ,"value" : "Human Resources"}, { "key" : "g_3_" ,"value" : "Participation"}, { "key" : "g_4_" ,"value" : "Effectiveness"} ],
}
const eol_pillar_indicators = {
    "qol_" : [{ "key" : "qol_1_" ,"value" :  "Education"}, { "key" : "qol_2_" ,"value" : "Health"}, { "key" : "qol_3_" ,"value" : "Housing & Shelter"}, { "key" : "qol_4_" ,"value" : "Wash & SWM"}, { "key" : "qol_5_" ,"value" : "Mobility"}, { "key" : "qol_6_" ,"value" : "Safety & Security"}, {  "key" : "qol_7_" ,"value" : "Recreation"}],
    "ea_"  :  [{ "key" : "ea_1_" ,"value" : "Level of Economic Development"}, { "key" : "ea_2_" ,"value" : "Economic Opportunities"}, /* { "key" : "ea_3_" ,"value" : "Gini Coefficient"},*/ ],
    "s_"   :  [{ "key" : "s_1_" ,"value" : "Environment"}, { "key" : "s_2_" ,"value" : "Green Spaces & Buildings"}, { "key" : "s_3_" ,"value" : "Energy Consumption"}, { "key" : "s_4_" ,"value" : "City Resilience"} ],
    "cp_"  :  []
}

const indicators = { eol : eol_pillar_indicators, mpi: mpi_pillar_indicators };

export {pillars, indicators}