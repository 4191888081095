import React from 'react';
import {infoColor} from "../assets/jss/livability.js";
import Link from '@material-ui/core/Link';

//import GridItem from "./GridItem.js";
//import GridContainer from "./GridContainer.js";

import cp_img1 from "../assets/img/cp_img1.png";
import cp_img2 from "../assets/img/cp_img2.png";
//import cp_img3 from "../assets/img/cp_img3.png";
import cp_img4 from "../assets/img/cp_img4.png";
//import cp_img6 from "../assets/img/cp_img6.png";

//import cp_img10 from "../assets/img/cp_img10.png";
//import cp_img12 from "../assets/img/cp_img12.png";
//import cp_img13 from "../assets/img/cp_img13.png";

import cp_img11 from "../assets/img/cp_img11.jpg";

import h1 from "../assets/img/funny/1.jpeg";
import h2 from "../assets/img/funny/2.jpeg";
import h3 from "../assets/img/funny/3.jpeg";
import h4 from "../assets/img/funny/4.jpeg";
import h5 from "../assets/img/funny/5.jpeg";
import h6 from "../assets/img/funny/6.jpeg";

import { Carousel } from 'react-responsive-carousel';
import Hidden from "@material-ui/core/Hidden";


export default function CitizenPerception(props){
    const imgs = [ h1, h2, h3,h4, h5, h6];

    const imgcarousal = imgs.map((img, i) => {
        return (
            <div key={i}  >
                <img src={img} alt="funnyimg" style={{maxWidth: '60vw'}}  />
            </div>
        );
    });
        
    return (
        <div style={{width:'75%'}}>
            <Hidden smDown implementation="css">
                <div style={{fontFamily:"poppins, roboto", fontSize:"large", color:infoColor[0], fontWeight:'bold', whiteSpace:'nowrap'}}>
                    Citizen Perception Survey (<Link href="https://www.eol2019.org" style={{color:infoColor[0], fontWeight:'bold'}}>eol2019.org</Link>)
                </div>
            </Hidden>
           
            <Hidden mdUp implementation="css">
                <div style={{fontFamily:"poppins, roboto", fontSize:"large", color:infoColor[0], fontWeight:'bold', whiteSpace:'nowrap'}}>
                    Citizen Perception Survey 
                </div>
                <p style={{border:'1px solid gray', padding:'5px', textAlign:'center'}}><Link href="https://www.eol2019.org" style={{color:infoColor[0], fontWeight:'bold'}}>eol2019.org</Link></p>
            </Hidden>
            
            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
                <img src={cp_img1} alt="cp" style={{width:'50%', float: 'left', padding:'20px'}} />
                The Ease of Living 2020 Citizen Perception Survey was conducted from 16th of January 2020 till 20th of March 2020. A total of 32.2 lakh citizens residing across the 111 cities that participated in the survey gave their considered feedback using various channels. The online channel, which citizens could access through  QR codes on printed advertisements or go directly through the designated URL, garnered 31,05,481 feedbacks,  which is nearly 2% of the combined projected population of these cities till 2019.  The online platform was also run independently through <Link href="https://mygov.in">MyGov.in</Link>, wherein 18,845 of their subscribers undertook the survey. Finally, feedback was also directly taken through a face-to-face survey conducted by Karvy field personnel, covering a total of 95,933 individual respondents chosen through a quota–based sampling to represent citizens from all walks of life.    
            </p>

            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
                As part of the Ease of Living Index Assessment, a Citizen Perception Survey (#MyCityMyPride) was conducted on behalf of the Ministry (which carries 30% of the marks of the Ease of Living Index). This survey sought to directly capture perception of citizens with respect to quality of life in their cities. This survey which was administered both online and offline commenced from the 16th of January 2020 and continued till the end of February 2020 for the online submission and till 20th of March for the face-to-face survey. The data for the survey was captured both online and offline. The online data came directly from citizens who accessed the survey through the dedicated web portal, or through the MyGovIndia portal, or responded through a face-to-face survey contact by Karvy’s on-ground enumerators.
            </p>

            <div style={{fontFamily:"poppins, roboto", fontSize:"large", color:infoColor[0]}}>Promotion of the survey</div>
            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
                A multi-pronged approach was adopted to promote the survey among citizens. This involved the following modes: 
            </p>

            <img src={cp_img2} alt="promotion" style={{width:'100%', marginLeft: 'auto', marginRight: 'auto'}} />

            <div style={{fontFamily:"poppins, roboto", fontSize:"medium", color:infoColor[0], paddingTop:'20px'}}>City level initiatives to engage with citizens</div>
            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
            The city administrations promoted this survey by putting up posters and hoardings in key places across each city, as well as releasing advertisements in newspapers. Citizens of this city were encouraged to wholeheartedly participate in this survey by scanning the QR code in the collaterals and advertisements or simply visit the dedicated web portal. While the Social Media and Communication teams created a large number of collaterals, for both hoardings as well as print media, cities were also encouraged to create their own collaterals using locally relevant pictures and messaging, while retaining the essential branding and key access and communication features. Most cities, in a bid to promote the survey among its citizens, became very creative with their outdoor collaterals which were designed to not only solicit participation, but also promote the #MyCityMyPride ethos.  
            </p>

             <img src={cp_img11} alt="promotion" style={{width:'100%', marginLeft: 'auto', marginRight: 'auto'}} /> 

            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
            Participating cities, on their own initiative, had undertaken various innovative steps to enable citizen participation in the survey. There are many such innovations that can be cited, for instance conducting of IEC campaigns through stalls and outreach activities in different events like the Ease of Living cycle rally in Sagar, stalls in the Surat Book Fair, or sensitization programme on Ease of Living Index Campaign on International Fair Ground in association with RED FM in Agartala and in BUILDMAT 2020 in Davanagere. Cities have also made efforts at focused/targeted outreach to citizens, like in reaching out to parents through school children who had to get survey filled out by their parents as homework in Hubbali, or rolling out the campaign in locations with large footfalls, such as AK pharmacy in Davanagere, food courts in Gwalior, and conducting Focus Group Discussions (FGDs) in education institutions and slums of Jabalpur and Saharanpur. Satna Corporation made an effort to specifically reach out to the elderly and young alike through Yoga centres across the city. 
            </p>

            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
            Innovation in improving visibility was noticed everywhere. To disseminate the information, hydraulic awareness vehicles roaming in major public gathering areas in Salem, autorickshaws and other public transports were engaged in Erode, balloon floats were deployed in Ujjain and human chains were formed in Indore. Jabalpur effectively used technology such as mobile kiosks and tablets at various places such as railway stations, bus stands, metro stations, and movie theatres.            </p>

            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
            Media outreach was also a major strategy and several cities leveraged the opportunity to forge partnerships with local media houses. Salem, Thane, and Surat city shot short films on the Citizen Perception Survey (CPS) and publicised on WhatsApp groups, Facebook and colleges, while Mangalore and Bhagalpur reached out to people through FM Radio broadcasting about the Survey.             </p>

            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
            To improve online access of citizens, several cities like Bengaluru, Kochi and Itanagar deployed hundreds of volunteers to interact with people and administer the survey. There were efforts at merchandising with messages being printed on t-shirts, mugs, and customised wedding cards.               </p>

            <img src={cp_img4} alt="promotion" style={{width:'100%', marginLeft: 'auto', marginRight: 'auto'}} />

            <div style={{fontFamily:"poppins, roboto", fontSize:"medium", color:infoColor[0], paddingTop:'20px'}}>Bulk SMS </div>
            <div style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
            The survey was also promoted through bulk SMS. Karvy, through its call centre services, already had a large data base of phone numbers of citizens who had participated in the various OBD surveys that Karvy undertakes from time to time; 15 lakh such citizens residing the 111 cities participating in the Liveability assessment exercise were sent bulk SMS containing the following message:  
            <p style={{border:'1px solid gray', padding:'5px'}}>Rate your city. Share your views, Participate in the Ease of Living Perception Survey @http://eol2019.org/Citizenfeedback, Min. of Housing and Urban Affairs</p>
            The SMS burst was sent during the last weekend of February and the first weekend of March 2020 (7.5 lakhs in each round). It was a conscious decision to delay the SMS burst till after all IEC activities had been completed.            </div>

            <div style={{fontFamily:"poppins, roboto", fontSize:"medium", color:infoColor[0], paddingTop:'20px'}}>Collaboration with MyGov.in  </div>
            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
            The survey was also promoted though a collaboration with MyGov.in, a citizen engagement platform founded by the Government of India to promote the active participation of Indian citizens in their country's governance and development. It is aimed at creating a common platform for Indian citizens to "crowdsource governance ideas from citizens". 
            MyGov, on their part, extensively used their Twitter, Facebook and Instagram handles as well as in their YouTube page. MyGov’s subscriber base was engaged by their creative team for period of 3 weeks in the month of February 2020 through creative tweets, posts, video and audio clips, and radio talk shows. The participants who provided their feedback using the <Link href="https://www.mygov.in/mygov-survey/ease-living-2019-20-citizen-perception-survey/">https://www.mygov.in/mygov-survey/ease-living-2019-20-citizen-perception-survey/</Link> link were also part of a lucky draw exercise with cash prizes and felicitations at the city and national levels. 
            
            </p>

            <Carousel 
                showArrows={true} 
                autoPlay={true} 
                dynamicHeight={false} 
                interval={10000} 
                infiniteLoop={true} 
                showThumbs={false}
                stopOnHover={false}
                style={{maxHeight:'90vh'}}
            >
                {imgcarousal}
            </Carousel>
{/*
            <div style={{fontFamily:"poppins, roboto", fontSize:"medium", color:infoColor[0], paddingTop:'20px'}}>Presence on the Social Media  </div>
            <p style={{fontFamily:"poppins, roboto", fontSize:"medium", textAlign:'justify'}}>
                As far as social media is concerned, the Ease of Living Campaign adopted a two-pronged strategy featuring organic reach and promotion by the 111 cities and paid push by the Ministry of Housing and Urban Affairs. The main objective of the social media campaign was to increase participation in the online Citizens’ Perception Survey hosted at a URL <a href="http://www.eol2019.org/">http://www.eol2019.org/</a>
            </p>

              <div style={{fontFamily:"poppins, roboto", fontSize:"large",  paddingBottom:'20px', color:infoColor[0]}}>Campaign Outcomes</div>
            <div style={{fontFamily:"poppins, roboto", fontSize:"medium", color:infoColor[0]}}>Survey content - 21 livability indicators </div>
            <GridContainer direction="row">
                <GridItem>
                <ul>
                            <li>    Affordable and quality education</li>
                            <li>    Safe commuting</li>
                            <li>	Livelihood opportunities </li>
                            <li>	Affordable and quality healthcare</li>
                            <li>	Adequacy of public transport</li>
                            <li>	Sufficiency of income to maintain QoL</li>
                            <li>	Affordable housing</li>
                            <li>	Affordability of commuting  </li>
                            <li>	Access to financial services</li>
                            <li>	Overall cleanliness </li>
                            <li>	Overall safety and security</li>
                        </ul>
                </GridItem>
                <GridItem>
                    <div>
                        <ul>
                            <li>	Quality of air</li>
                            <li>	Efficacy of garbage collection system</li>
                            <li>	Efficiency of emergency services</li>
                            <li>	Adequacy of green cover</li>
                            <li>	Quality of drinking water supply </li>
                            <li>	Women's safety in public places</li>
                            <li>	Quality of electricity supply </li>
                            <li>	Incidence of water logging</li>
                            <li>	Adequacy of recreational facilities</li>
                            <li>	Affordable electricity</li>
                        </ul>
                    </div>
                </GridItem>
                <GridItem>
                    <div>Cities with a million + population</div>
                    <div>
                    <ul>
                            <li>	Solapur</li>
                            <li>	Jodhpur </li>
                            <li>	Chennai</li>
                    </ul>
                    </div>
                
                </GridItem>
                <GridItem>
                    <div>Cities with less than a million population</div>
                    <ul>
                            <li>	Bhubaneshwar</li>
                            <li>	Silvassa </li>
                            <li>	Davangere</li>
                    </ul>
                </GridItem>
            </GridContainer>

            <div style={{fontFamily:"poppins, roboto", fontSize:"medium", paddingBottom:'20px', color:infoColor[0]}}>City ratings under Municipal Performance Index </div>

            <GridContainer direction="row">
                
            </GridContainer>
 */}
        </div>
    ) 
}