import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import GridItem from "./GridItem.js";
import GridContainer from "./GridContainer.js";

import {infoColor} from "../assets/jss/livability.js";
import states from '../json/states.js';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import Clicktip from './Clicktip.js';

import {pillars, indicators} from '../json/pillars_indicators.js';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

  const selectStyles = makeStyles({
    container: {
      marginTop: "10%",
    },
    formControl: {
      minWidth: 120,
    },
    label: {
      color: infoColor[0],
      "&.Mui-focused": {
        color: infoColor[0],
      },
    },
    select: {
      "&:hover": {
        borderBottomColor: infoColor[0]
      },
      "&:before": {
        borderBottomColor: infoColor[0],
      },
      "&:after": {
        borderBottomColor: infoColor[0],
      },
      "& .MuiSvgIcon-root": {
        color: infoColor[0],
      },
    },
    
  });

  const toggleStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        borderColor: infoColor[0],
        color: infoColor[0],
        whiteSpace: 'nowrap'
    },
    label: {
        color: infoColor[0],
        width: '100%'
    },
    selected: {
        backgroundColor: 'red'
    }

  }));
  
export default function Selectors(props){
    const [clicktipState, setClicktipState] = React.useState(false);
    const classes3 = toggleStyles();
    const classes4 = selectStyles();

    return (

            <GridContainer style={{width:'75%'}} direction="row" justify="space-between" spacing={1}>
                <GridItem style={{margin:'5px'}} xs={12} sm={4} md={4} lg={4} xl={4}><div style={{display:'flex', direction:'row'}}>
                <ToggleButtonGroup key="index" name="index" value={props.filters.index} exclusive >
                    <ToggleButton name="index" value="eol" onClick={(e) => props.changeFilters(e,"eol", "index" )}
                        classes={props.filters.index === "eol" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                        style={props.filters.index === "eol" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Ease of living</ToggleButton>

                    <ToggleButton name="index" value="mpi" onClick={(e) => props.changeFilters(e, "mpi", "index")}
                    classes={props.filters.index === "mpi" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                    style={props.filters.index === "mpi" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Municipal performance </ToggleButton>
                </ToggleButtonGroup></div>
                </GridItem>

                <GridItem style={{margin:'5px'}} xs={12} sm={4} md={4} lg={4} xl={4}><div style={{display:'flex', direction:'row', alignItems:'center'}}>
                <ToggleButtonGroup key="category" name="category" value={props.filters.category} exclusive >
                <ToggleButton name="category" value="om" onClick={(e) => props.changeFilters(e,"om", "category" )}
                            classes={props.filters.category === "om" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                            style={props.filters.category === "om" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Above 1 Million</ToggleButton>

                <ToggleButton name="category" value="um" onClick={(e) => props.changeFilters(e, "um", "category")}
                        classes={props.filters.category === "um" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                        style={props.filters.category === "um" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Below 1 Million </ToggleButton>
                </ToggleButtonGroup>
                <Clicktip
                  title="City classification based on population estimates as of July 2019"
                  open={clicktipState}
                  close={() => setClicktipState(false)}

                >
                  <InfoIcon style={{color: infoColor[0], paddingLeft:'5px', cursor: 'pointer'}} onClick={() => setClicktipState(true)} />
                </Clicktip></div>
            </GridItem>

            <GridItem style={{margin:'5px'}} xs={12} sm={4} md={4} lg={4} xl={4}>
            <FormControl >
            <InputLabel shrink id="pillarlabel" style={{color:infoColor[0]}}>
              { props.filters.index === "eol" ? "Select a pillar" : "Select a vertical" }
            </InputLabel>
            <Select
              id="pillar"
              value={props.filters.pillar}
              onChange={(e) => props.changeFilters(e, e.target.value, "pillar")}
              displayEmpty
              className={classes4.select}
              style={{width:280, border:'1px solid infoColor[0]'}}
            >
              <MenuItem value="">
                All
              </MenuItem>
              {
                pillars[props.filters.index].map(p => {
                  return (<MenuItem key={p.key} value={p.key}>{p.value}</MenuItem>);
                })
              }
            </Select>
            </FormControl>
            </GridItem>
            <GridItem style={{margin:'5px'}} xs={12} sm={4} md={4} lg={4} xl={4}>
              <FormControl >
              <InputLabel shrink id="indicatorlabel" style={{color:infoColor[0]}}>
                { props.filters.index === "eol" ? "Select a category" : "Select a sector" }
              </InputLabel>
              <Select
                id="indicator"
                value={props.filters.indicator}
                onChange={(e) => props.changeFilters(e, e.target.value, "indicator")}
                displayEmpty
                className={classes4.select}
                style={{width:280, border:'1px solid infoColor[0]'}}
              >
                <MenuItem value="">
                  All
                </MenuItem>
                {
                  props.filters.pillar !== "" ?
                  indicators[props.filters.index][props.filters.pillar].map(p => {
                    return (<MenuItem key={p.key} value={p.key}>{p.value}</MenuItem>);
                  }) : []
                }
              </Select>
              </FormControl>
            </GridItem>
            <GridItem style={{margin:'5px'}} xs={12} sm={4} md={4} lg={4} xl={4}>
            <FormControl >
              <InputLabel shrink id="statelabel" style={{color:infoColor[0]}}>
                State
              </InputLabel>
              <Select
                id="state"
                value={props.filters.state}
                onChange={(e) => props.changeFilters(e, e.target.value, "state")}
                displayEmpty
                className={classes4.select}
                style={{width:280, border:'1px solid infoColor[0]'}}
              >
                <MenuItem value="">
                  All
                </MenuItem>
                {
                  states.map(state=> {
                    return (<MenuItem key={state.name} value={state.name}>{state.name}</MenuItem>);
                  })
                }
              </Select>
              </FormControl>
            </GridItem>

            <GridItem style={{margin:'5px'}} xs={12} sm={4} md={4} lg={4} xl={4}>
                <ToggleButtonGroup key="display" name="display" value={props.filters.display} exclusive >
                <ToggleButton name="display" value="map" onClick={(e) => props.changeFilters(e,"map", "display" )}
                            classes={props.filters.display === "map" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                            style={props.filters.display === "map" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Map view</ToggleButton>

                <ToggleButton name="display" value="chart" onClick={(e) => props.changeFilters(e, "chart", "display")}
                        classes={props.filters.display === "chart" ? {root: classes3.root} : {root: classes3.root, label: classes3.label}} 
                        style={props.filters.display === "chart" ? {backgroundColor:infoColor[0], color:'white'} : {}} size="small">Bar chart </ToggleButton>
                </ToggleButtonGroup>
            </GridItem>

            </GridContainer>

    );
}