import React from "react";

import GridItem from "./GridItem.js";
import GridContainer from "./GridContainer.js";
import {infoColor} from "../assets/jss/livability.js";

import eol_m from '../assets/img/eol_m.jpg';
import eol from '../assets/img/eol.jpg';
import cps from '../assets/img/cps.jpg';

import mpi_m from '../assets/img/mpi_m.jpg';
import mpi from '../assets/img/mpi.jpg';


export default function Resources() {
    function showDoc(filename){
        const path = "https://livabilitystore175634-prod.s3.amazonaws.com/public/docs/"+filename;
        window.open(path, "_blank");
    }

    return (
        <div style={{display:'flex', flexDirection: 'column', justifyContent:'space-between' }}>
            <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px', paddingBottom:'20px'}}>Assessment Reports</div>
            <GridContainer spacing={10} justify='flex-start'  > 
                <GridItem >
                        <img src={eol} alt="eol" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('Ease_of_Living_Report.pdf')}/>
                        <div style={{fontFamily:"Poppins, Roboto", fontSize:'medium', color:infoColor[0], paddingBottom:'20px', textAlign:'center', cursor:'pointer'}} onClick={() => showDoc('Ease_of_Living_Report.pdf')}>Ease of Living Report</div>
                </GridItem>
                <GridItem >
                        <img src={mpi} alt="mpi" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('MPI_Report.pdf')} />
                        <div style={{fontFamily:"Poppins, Roboto", fontSize:'medium', color:infoColor[0], paddingBottom:'20px', textAlign:'center', cursor:'pointer'}} onClick={() => showDoc('MPI_Report.pdf')}>Municipal Performance Report</div>
                </GridItem>
            </GridContainer>
            <hr style={{width:'100%', height:'1px',  color:infoColor[0], backgroundColor:infoColor[0]}}/>
            
            <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px', paddingBottom:'20px'}} >Citizen Perception</div>
            <GridContainer spacing={10} justify='flex-start'  > 
                <GridItem >
                    <img src={cps} alt="cps" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('Citizen_Perception_Survey_Report.pdf')}/>
                    <div style={{fontFamily:"Poppins, Roboto", fontSize:'medium', color:infoColor[0], paddingBottom:'20px', textAlign:'center', cursor:'pointer'}} onClick={() => showDoc('Citizen_Perception_Survey_Report.pdf')} >Citizen Perception Survey Report</div>
                </GridItem>
            </GridContainer>
            <hr style={{width:'100%', height:'1px',  color:infoColor[0], backgroundColor:infoColor[0]}}/>
            
            <div style={{fontFamily:"Poppins, Roboto", fontSize:'large', fontWeight:'bold', color:infoColor[0], paddingTop:'20px', paddingBottom:'20px'}}>Methodology Documents</div>
            <GridContainer spacing={10} justify='flex-start'  > 
                <GridItem>
                        <img src={eol_m} alt="eol" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('EoL_Methodology.pdf')} />
                        <div style={{fontFamily:"Poppins, Roboto", fontSize:'medium', color:infoColor[0], paddingBottom:'20px', textAlign:'center', cursor:'pointer'}} onClick={() => showDoc('EoL_Methodology.pdf')}>Ease of Living Methodology</div>
                </GridItem>

                <GridItem>
                        <img src={mpi_m} alt="mpi" style={{width: '250px', paddingBottom:'5px', cursor:'pointer'}} onClick={() => showDoc('MPI_Methodology.pdf')}  />
                        <div style={{fontFamily:"Poppins, Roboto", fontSize:'medium', color:infoColor[0], paddingBottom:'20px', textAlign:'center', cursor:'pointer'}} onClick={() => showDoc('MPI_Methodology.pdf')}>Municipal Performance Methodology</div>
                </GridItem>
            </GridContainer>

        </div>
    );
}