
import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import cities from '../json/fieldimages.js'; 
import {infoColor} from "../assets/jss/livability.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import Next from '@material-ui/icons/KeyboardArrowRight';
import Prev from '@material-ui/icons/KeyboardArrowLeft';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import ReactPlayer from 'react-player';
import Card from "./Card.js";
import CardHeader from "./CardHeader.js";
import CardBody from './CardBody.js';
import { Carousel } from 'react-responsive-carousel';
import '../assets/css/carousel.css';



const autoCompleteStyles = makeStyles(theme => ({
  inputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: '2px',
      borderColor: infoColor[0]
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: infoColor[0]
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: infoColor[0]
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: infoColor[0]
    },
    "&:not(.Mui-disabled):hover::after": {
      borderColor: infoColor[0]
    }
  }
}));


const textStyles = makeStyles(theme => ({
  formControlRoot: {
    position: "relative",
    "& label.Mui-focused": {
      color: infoColor[0]
    },
    "& .MuiInput-underline": {
      borderColor: infoColor[0]
    },
    "& .MuiInput-underline::before": {
      borderColor: infoColor[0]
    },
    "& .MuiInput-underline::after": {
      borderColor: infoColor[0]
    },

  },
  inputLabelRoot: {
    color: infoColor[0],
    "&.focused": {
      color: infoColor[0]
    },
  },
}));


function PhotoGallery() {
  const classes = autoCompleteStyles();
  const classes1 = textStyles();
  const [current, setCurrent] = React.useState(0);

  const prev = () => {
    if(current > 0){
      setCurrent(current-1);
    }
    else{
      setCurrent(cities.length - 1);
    }
  }

  const next = () => {
    if(current < cities.length - 1 ){
      setCurrent(current+1);
    }
    else{
      setCurrent(0);
    }
  }

  const selectCity = (name) => {
    setCurrent(cities.findIndex(c => c.city === name));
  }

    const imgArray = cities[current].images.map((imge, i) => {
      return (
        { 
          original: "https://livabilitystore175634-prod.s3.amazonaws.com/public/"+imge.key,
          thumbnail: "https://livabilitystore175634-prod.s3.amazonaws.com/public/"+imge.key
        }
      )
    });

  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:"center", justifyContent:'space-between', padding:'20px'}}>
      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', flex: 1, paddingBottom:'50px', paddingTop:'10px'}}>
          <div><IconButton onClick={prev}><Prev style={{color:infoColor[0], fontSize:'36px'}}/></IconButton></div>

          <Autocomplete 
                id="city"
                name="city"
                key="city"
                value={cities[current].city}
                style={{ width: 200 }}
                classes={classes}
                options={cities.map(city => city.city)}
                disableClearable
                renderInput={
                    (params) => 
                    <TextField 
                        {...params} 
                        label="Select a city" 
                        classes={{ root: classes1.formControlRoot }} 
                        variant="outlined"
                    />
                }
                onChange={(e,v) => selectCity(v)}
            />

          <div><IconButton onClick={next}><Next style={{color:infoColor[0], fontSize:'36px'}}/></IconButton></div>
      </div>

      

          <ImageGallery items={imgArray} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} />

          <Card style={{marginBottom: 0, marginTop: '50px', backgroundColor:'black'}}>
                <CardHeader color="info">
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <div>
                    </div>
                    <div style={{fontSize:'large', fontWeight: 'bold'}}>
                     Previous launch videos
                    </div>
                  </div>
                </CardHeader>
                <CardBody style={{display:'flex', justifyContent:'center', backgroundColor:'black'}}>
                <Carousel 
                            showArrows={true} 
                            autoPlay={false} 
                            dynamicHeight={false} 
                            showThumbs={false}
                            stopOnHover={false}
                        >
                            <ReactPlayer width="100%" url={"https://www.youtube.com/watch?v=_JL8mQ_2ah8"} key={"vid-2019"}  />
                            <ReactPlayer width="100%" url={"https://www.youtube.com/watch?v=cdW5nFUIbLY"} key={"vid-2018"} />
                        </Carousel>
                </CardBody>
              </Card>
    </div>
  );
}

export default PhotoGallery;