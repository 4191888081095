import {
    drawerWidth,
    transition,
    boxShadow,
    defaultFont,
    primaryColor,
    primaryBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    whiteColor,
    /* grayColor, */
    blackColor,
    hexToRgb
  } from "../../livability.js";
  
  const menubarStyle = theme => ({
    drawerPaper: {
      border: "none",
       position: "fixed", 
      top: "0",
      bottom: "0",
      left: "0",  
      zIndex: "1",
      ...boxShadow,
      width: drawerWidth,
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        position: "fixed",
        height: "100%"
      },
      [theme.breakpoints.down("sm")]: {
        width: drawerWidth,
        ...boxShadow,
        position: "fixed",
        display: "block",
        top: "0",
        height: "100vh",
        right: "0",
        left: "auto",
        zIndex: "1032",
        visibility: "visible",
        overflowY: "visible",
        borderTop: "none",
        textAlign: "left",
        paddingRight: "0px",
        paddingLeft: "0",
        transform: `translate3d(${drawerWidth}px, 0, 0)`,
        ...transition
      }
    },
    drawerPaperRTL: {
      [theme.breakpoints.up("md")]: {
        left: "auto !important",
        right: "0 !important"
      },
      [theme.breakpoints.down("sm")]: {
        left: "0  !important",
        right: "auto !important"
      }
    },

    background: {
      position: "absolute",
      zIndex: "1",
      height: "100%",
      width: "100%",
      display: "block",
      top: "0",
      left: "0",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      "&:after": {
        position: "absolute",
        zIndex: "3",
        width: "100%",
        height: "100%",
        content: '""',
        display: "block",
        background: blackColor,
        opacity: ".7"
      }
    },
    list: {
      width:'100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: infoColor[0],
    },
    item: {
      backgroundColor: infoColor[0],
      textDecoration: "none",
      "&:hover,&:focus,&:visited,&": {
        color: infoColor[0]
      }
    },
    
    itemLink: {
      width: "auto",
      transition: "all 300ms linear",
      marginLeft: "10px",
      marginRight: "10px",
      borderRadius: "3px",
      position: "relative",
      display: "block",
      padding: "10px 15px",  
      backgroundColor: "transparent",
      ...defaultFont
    },
    itemIcon: {
      width: "24px",
      height: "30px",
      fontSize: "24px",
      lineHeight: "30px",
      float: "left",
      marginRight: "15px",
      textAlign: "center",
      verticalAlign: "middle",
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
    },
    itemIconRTL: {
      marginRight: "3px",
      marginLeft: "15px",
      float: "right"
    },
    itemText: {
      ...defaultFont,
      margin: "0",
      lineHeight: "15px",
      fontSize: "16px",
      color: whiteColor,
      fontWeight: 'bold',
    },
    itemTextRTL: {
      textAlign: "right"
    },
    whiteFont: {
      color: whiteColor
    },
    blueFont: {
      color: infoColor[0]
    },
    purple: {
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
      "&:hover,&:focus": {
        backgroundColor: primaryColor[0],
        ...primaryBoxShadow
      }
    },
    blue: {
      backgroundColor: 'white',
      /* boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.2)",
      */
      "&:hover,&:focus": {
        backgroundColor: 'white',
        /* boxShadow:
          "0 12px 20px -10px rgba(" +
          hexToRgb(infoColor[0]) +
          ",.28), 0 4px 20px 0 rgba(" +
          hexToRgb(blackColor) +
          ",.12), 0 7px 8px -5px rgba(" +
          hexToRgb(infoColor[0]) +
          ",.2)" */
      } 
    },
    green: {
      backgroundColor: successColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(successColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ",.2)",
      "&:hover,&:focus": {
        backgroundColor: successColor[0],
        boxShadow:
          "0 12px 20px -10px rgba(" +
          hexToRgb(successColor[0]) +
          ",.28), 0 4px 20px 0 rgba(" +
          hexToRgb(blackColor) +
          ",.12), 0 7px 8px -5px rgba(" +
          hexToRgb(successColor[0]) +
          ",.2)"
      }
    },
    orange: {
      backgroundColor: warningColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.2)",
      "&:hover,&:focus": {
        backgroundColor: warningColor[0],
        boxShadow:
          "0 12px 20px -10px rgba(" +
          hexToRgb(warningColor[0]) +
          ",.28), 0 4px 20px 0 rgba(" +
          hexToRgb(blackColor) +
          ",.12), 0 7px 8px -5px rgba(" +
          hexToRgb(warningColor[0]) +
          ",.2)"
      }
    },
    red: {
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.2)",
      "&:hover,&:focus": {
        backgroundColor: dangerColor[0],
        boxShadow:
          "0 12px 20px -10px rgba(" +
          hexToRgb(dangerColor[0]) +
          ",.28), 0 4px 20px 0 rgba(" +
          hexToRgb(blackColor) +
          ",.12), 0 7px 8px -5px rgba(" +
          hexToRgb(dangerColor[0]) +
          ",.2)"
      }
    },
    sidebarWrapper: {
      width:'70%',
      zIndex: "4",
      overflowScrolling: "touch",
      display:'flex',
      justifyContent:'center',
      backgroundColor: infoColor[0],
    },
    sidebarWrapper1: {
      width:'30%',
      zIndex: "4",
      overflowScrolling: "touch",
      display:'flex',
      justifyContent:'center',
      backgroundColor: infoColor[0],
    },
    activePro: {
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        width: "100%",
        bottom: "13px"
      }
    }
  });
  
  export default menubarStyle;
  