import React from 'react';
import {infoColor, warningColor,dangerColor,successColor} from "../assets/jss/livability.js";
import Popover from '@material-ui/core/Popover';
import Link from '@material-ui/core/Link';

export default function Legend(props){

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


    return (
        <div>
          <Link href="#" style={{color:infoColor[0]}} onClick={handleClick}>
              What does the rank's color indicate?
            </Link>
            
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
         <div style={{display:'flex', flexDirection:'column', backgroundColor:'white', padding: '5px 10px', }}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <div style={{height: '20px', width: '20px', backgroundColor: dangerColor[0]}} /><div style={{padding:'10px'}}>Score &lt; 30</div>
              </div>

              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <div style={{height: '20px', width: '20px', backgroundColor: warningColor[0]}} /><div style={{padding:'10px'}}>Score between 30 &amp; 50</div>
              </div>

              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <div style={{height: '20px', width: '20px',   backgroundColor: successColor[0]}} /><div style={{padding:'10px'}}>Score &gt; 50</div>
              </div>
              
            </div>
      </Popover>
      </div>


    )
}