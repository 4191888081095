import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CityProfile from './CityProfile.js'
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import Back from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/livability/components/headerStyle.js";
import {infoColor} from "../assets/jss/livability.js";
import logo from "../assets/img/emblem.svg"


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);
export default function ResponsiveDialog(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
          PaperProps={{
            style: {
              backgroundColor: 'rgb(238, 238, 238)',
            },
          }}
        fullScreen={true}
        TransitionComponent={Transition}
        open={props.city !== null}
        onClose={props.handleClose}
      >


       <DialogActions style={{padding: 0}}>
          <div style={{display:'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'space-between'}}>
            <IconButton onClick={props.handleClose}><Back style={{color:infoColor[0], fontSize:'36px'}}/></IconButton>
            <div className={classes.title} style={{position: 'absolute', left: '50%', transform: 'translate(-50%, 0)', fontSize: '26px',}}>{props.city ? props.city.city : ""}</div>
            <div style={{display:'flex', flexDirection:"column", alignItems:"center", justifyContent:"center", padding:"15px"}}>
              <img src={logo} alt="MohuA" height="42px"  />
              <div style={{textAlign:"center", color:infoColor[0], fontSize:'small'}}></div>
            </div>

          </div>
        </DialogActions>

        <DialogContent>
          <CityProfile city={props.city ? props.city.city.toLowerCase() : null} next={props.next} prev={props.prev} close={props.handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}