import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
//import Menu from "@material-ui/icons/Menu";
import styles from "../assets/jss/livability/components/headerStyle.js";
//import {infoColor} from "../assets/jss/livability.js";

import ifc from "../assets/img/ifc.png";
import aatmanirbhar from "../assets/img/aatmanirbhar.png";
import mohua from "../assets/img/mohua.png";
import swacchbharat from "../assets/img/swacchbharat.png";
import smartcity from "../assets/img/smartcity.png";

import routes from "../routes.js";
import Menubar from "./Menubar.js";

import Menu from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

import {infoColor} from "../assets/jss/livability.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const colour = "blue";

  /* const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  }; */

  //var property = props.routes.find(prop => (window.location.href.indexOf(prop.layout + prop.path) !== -1)); 

  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses} style={{backgroundColor: '#343935'}}>
      <Hidden smDown implementation="css">
      <Toolbar className={classes.container} style={{display:'flex', flexDirection:'column'}}>
         <div style={{display:'flex', flexDirection:"row", justifyContent:'space-around', alignItems:'center', width:'80%', backgroundColor:'transparent'}}>
         <div>
            <img src={ifc} alt="Insitute for Competitiveness" height="36px"  />
          </div>
         <div>
            <img src={smartcity} alt="Smart City Mission India" height="48px"  />
          </div>
          <div>
            <img src={mohua} alt="Ministry of Housing & Urban Affairs"  height="60px"  />
          </div>
          <div>
            <img src={aatmanirbhar} alt="Atma Nirbhar" height="48px" />
          </div>
          <div>
            <img src={swacchbharat} alt="Swacch Bharat" height="36px" />
          </div>
         </div>
      </Toolbar>
      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', backgroundColor: infoColor[0]} }>
            <Menubar
              routes={routes}
              handleDrawerToggle={props.handleDrawerToggle}
              closeDrawerToggle={props.closeDrawerToggle}
              color={colour}
              style={{width:'100%'}}
            />
      </div>
      
      </Hidden>
      <Hidden mdUp implementation="css">
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'5px', alignItems:'center'}}>
          {/* <div style={{fontFamily: "Poppins, Roboto", fontSize: 'large', color:'white', fontWeight:'bold', border:'2px solid white', padding:'3px'}}>
             City Rankings 2020
          </div> */}
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center'}}>
            <div>
              <img src={mohua} alt="Ministry of Housing & Urban Affairs"  height="80px" style={{paddingRight:'5px'}} />
            </div>
            <div style={{fontFamily: "Poppins, Roboto", fontSize: 'x-large', width:'120px' , color:'white', borderLeft: '2px solid white', paddingLeft:'5px', fontWeight:'bold'}}>
              City Rankings 2020
            </div>
          </div>
          <div>
          <IconButton
            color="inherit"
            onClick={props.handleDrawerToggle}
          >
            <Menu style={{color: infoColor[0]}} />
          </IconButton>
          </div>
        </div>
      </Hidden>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
