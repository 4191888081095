const fieldimages = [
    {
      "city": "Agartala",
      "images": [
        {
          "key": "pictures/AgartalaEOL/Public consultation 2.jfif",
          "eTag": "\"7272b34a0c6693bf676579fe1cd91f3c\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 105006
        },
        {
          "key": "pictures/AgartalaEOL/Public consultation 3.jfif",
          "eTag": "\"2c24e2c5c548dfbe7fd9354666ecb8b0\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 133660
        },
        {
          "key": "pictures/AgartalaEOL/Public consultation 4.jfif",
          "eTag": "\"3b98d57bc7d13eff7940a8c081e7901f\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 143028
        },
        {
          "key": "pictures/AgartalaEOL/Reshmi  Ghosh  - 1.jfif",
          "eTag": "\"5ac012b1e1d2ebe88c09d6835d2b0549\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 234398
        }
      ]
    },
    {
      "city": "Aligarh",
      "images": [
        {
          "key": "pictures/AligarhEOL/Aligarh  Nagar Nigam - IMG-20200210-WA0138.jpg",
          "eTag": "\"24da9f7f95c292fd474f1ebf428d7134\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 203136
        },
        {
          "key": "pictures/AligarhEOL/Aligarh  Nagar Nigam - IMG-20200210-WA0140.jpg",
          "eTag": "\"939143fa0ced65f48b65fe7b27ca1124\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 215592
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200207-WA0048.jpg",
          "eTag": "\"931daff1428099fa7a40675905e52688\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 242619
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200209-WA0039.jpg",
          "eTag": "\"4663e803b97b0db38f22995f0965bd2e\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 204427
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200209-WA0049.jpg",
          "eTag": "\"013c7c0ddafd810a8d30882f767f3e8d\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 135341
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200209-WA0050.jpg",
          "eTag": "\"c1431b31f4b63d6cf20f4c4ff27c4800\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 134939
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200210-WA0141.jpg",
          "eTag": "\"356b08b9094e79bc4fa9b5cb06710580\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 134827
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200218-WA0073.jpg",
          "eTag": "\"8239c1bf0078cf7e4e11907c30a8f79a\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 255443
        }
      ]
    },
    {
      "city": "Aizawl",
      "images": [
        {
          "key": "pictures/AizawlEOL/Lalramliana Varte - Aizawl 28 Feb Hoardings (2).jpg",
          "eTag": "\"659d6ffe2693be1b18bd18283b294bb6\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 242409
        },
        {
          "key": "pictures/AizawlEOL/Lalramliana Varte - Aizawl 28 Feb Hoardings (3).jpg",
          "eTag": "\"f3e0edd8726b2b2ab6ccdc9a0e3340c2\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 253157
        }
      ]
    },
    {
      "city": "Ajmer",
      "images": [
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (13).jpeg",
          "eTag": "\"963fd2e8d1b8e1c3ab46ad88146343c4\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 169168
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (14).jpeg",
          "eTag": "\"e78ab5b3ab9ae6e0ded94ef78fcf6b0e\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 236328
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (15).jpeg",
          "eTag": "\"83f86d60d22cf8507ce9f08823a21e8a\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 88574
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (2).jpeg",
          "eTag": "\"42402ebc22eb7a440ab3ee6b16efd3f1\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 244284
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (3).jpeg",
          "eTag": "\"538a3a0f644a08573cdd9d3fda11c8b2\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 239933
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (4).jpeg",
          "eTag": "\"676b525652abf013cb3d44cf7d1560ce\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 271610
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (5).jpeg",
          "eTag": "\"9283682453212fb93b5d7221c9d04e0e\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 188517
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (6).jpeg",
          "eTag": "\"38a981b55761981e5bc5fc91d43700d6\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 260459
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (7).jpeg",
          "eTag": "\"108feab8d4e02042baa9ad851e0b251d\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 203731
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (9).jpeg",
          "eTag": "\"ef78e968699e37e35d63588ea1bb5cf9\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 199354
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Collectorate office Ajmer.jpeg",
          "eTag": "\"4a49f1ff2aabbf6e49e3f12d7d6de285\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 241946
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Near Ajmer Bus Stand.jpeg",
          "eTag": "\"f18a687f1c377c0428a4a70ff2c144b6\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 171722
        }
      ]
    },
    {
      "city": "Amravati",
      "images": [
        {
          "key": "pictures/AmravatiEOL/Amravati Municipal Corporation - WhatsApp Image 2020-02-09 at 4.30.31 PM(1).jpeg",
          "eTag": "\"95033cc2202a191678743fd5b20802f1\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 98570
        },
        {
          "key": "pictures/AmravatiEOL/Amravati Municipal Corporation - WhatsApp Image 2020-02-09 at 4.30.31 PM.jpeg",
          "eTag": "\"87c08caa6e92e64578418638fea32d2c\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 134049
        },
        {
          "key": "pictures/AmravatiEOL/Amravati Municipal Corporation - WhatsApp Image 2020-02-09 at 4.30.35 PM.jpeg",
          "eTag": "\"812444a54f71d143bdbd3ae6fef21f81\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 93176
        }
      ]
    },
    {
      "city": "Aurangabad",
      "images": [
        {
          "key": "pictures/AurangabadEOL/Abdul Baari - Aurangabad - Aurangpura Sq. 2 BQS (3).jpeg",
          "eTag": "\"7a321104a4d0461c92ad61be89965806\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 151053
        },
        {
          "key": "pictures/AurangabadEOL/Abdul Baari - Aurangabad - Cidco Chwok BQS (1).jpeg",
          "eTag": "\"a199a30d4997d71d63cad0252ec74c42\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 115234
        },
        {
          "key": "pictures/AurangabadEOL/Abdul Baari - Aurangabad - Cidco Chwok BQS (3).jpeg",
          "eTag": "\"e37319a487696b5b5f4725a1bb38e1e9\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 167734
        }
      ]
    },
    {
      "city": "Barielly",
      "images": [
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-12 at 10.00.24 PM.jpeg",
          "eTag": "\"702a25fdf0f6e1f1789f98e9ab880e8b\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 100956
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 11.35.42 AM.jpeg",
          "eTag": "\"16c90105d1104276ec510e190cafe69e\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 129248
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 12.37.22 PM.jpeg",
          "eTag": "\"dbfb3bcb9b725ed76c2d2be3309d99de\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 170662
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 12.43.43 PM.jpeg",
          "eTag": "\"9d9ad0cf714d449cadbf12857a496e33\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 136110
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 4.51.06 PM (1).jpeg",
          "eTag": "\"6d3c75d59a6c1e0f3d1b9e0ed1ffedfd\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 195311
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 4.51.49 PM (1).jpeg",
          "eTag": "\"a18e4056c5472ab14e8b1663d0d9614f\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 138256
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-14 at 4.04.35 PM.jpeg",
          "eTag": "\"35fe287a5fc4248944628abbf793b432\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 93382
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-14 at 4.06.01 PM.jpeg",
          "eTag": "\"8d3bba3f9deac01b8686483231cd6704\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 103806
        }
      ]
    },
    {
      "city": "Belagavi",
      "images": [
        {
          "key": "pictures/BelagaviEOL/Belagavi Smartcity - WhatsApp Image 2020-02-11 at 12.59.41 PM.jpeg",
          "eTag": "\"8300bddca37ba374bd49e69f0527dd00\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 198199
        }
      ]
    },
    {
      "city": "Chennai",
      "images": [
        {
          "key": "pictures/ChennaiEOL/IEC MATERIAL/WhatsApp Image 2020-03-23 at 10.26.44 AM (1).jpeg",
          "eTag": "\"24ca01a5456cf12a3e7f0cfe341ad3e3\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 104842
        },
        {
          "key": "pictures/ChennaiEOL/IEC MATERIAL/WhatsApp Image 2020-03-23 at 10.26.44 AM.jpeg",
          "eTag": "\"5cc76caac1bea6d90cb8969aa8a07487\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 170640
        },
        {
          "key": "pictures/ChennaiEOL/IEC MATERIAL/WhatsApp Image 2020-03-23 at 10.26.45 AM.jpeg",
          "eTag": "\"06be4fc6a3d01de4da1341a771499177\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 121551
        }
      ]
    },
    {
      "city": "Dahod",
      "images": [
        {
          "key": "pictures/DahodEOL/IT Head - 2.jpeg",
          "eTag": "\"835a305c35a644117c19081fd6f71207\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 93340
        },
        {
          "key": "pictures/DahodEOL/IT Head - 3.jpeg",
          "eTag": "\"fd1bb532d2731574385c82857b7d9cd1\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 111852
        },
        {
          "key": "pictures/DahodEOL/IT Head - 5.jpeg",
          "eTag": "\"51bde7a0245525b5322bbfae30e51e01\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 122945
        },
        {
          "key": "pictures/DahodEOL/IT Head - Govindnagar.jpeg",
          "eTag": "\"ab40a99bc87cf690019391bf9217be9b\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 91429
        },
        {
          "key": "pictures/DahodEOL/IT Head - PadavCircle.jpeg",
          "eTag": "\"ec0b11c2a02d814fcd038c4db956e23f\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 124450
        }
      ]
    },
    {
      "city": "Davangere",
      "images": [
        {
          "key": "pictures/DavangereEOL/CHANDRASHEKAR  S K - Press Meet 2020-02-26.jpeg",
          "eTag": "\"c125e517439f341a922b199b6387b351\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 145415
        },
        {
          "key": "pictures/DavangereEOL/CHANDRASHEKAR  S K - Press meet 2020-02-26 (2).jpeg",
          "eTag": "\"2b3d3680d41da6b038a52b690f238c89\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 75553
        }
      ]
    },
    {
      "city": "Dehradun",
      "images": [
        {
          "key": "pictures/DehradunEOL/WhatsApp Image 2020-03-19 at 18.16.30.jpeg",
          "eTag": "\"3b79994290c51b45f0497f8aafa2b28b\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 146111
        }
      ]
    },
    {
      "city": "Dindigul",
      "images": [
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200219-WA0049.jpg",
          "eTag": "\"b5ea76baa85a04c175efc53d47b96863\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 65346
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200219-WA0067.jpg",
          "eTag": "\"516ed433b22f36e43ed6e0d383d68cac\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 66362
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200219-WA0133.jpg",
          "eTag": "\"50858eb5d813710449571a718fd06173\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 126629
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200220-WA0049.jpg",
          "eTag": "\"6fbaa4c24f6c7116a9df9b5f6c75e3cd\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 78332
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200220-WA0095.jpg",
          "eTag": "\"342521cbdb302532ff6278bd84ff836f\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 178098
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200220-WA0097.jpg",
          "eTag": "\"3eddad20e989507b12382c81586765d7\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 224738
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200228-WA0046.jpg",
          "eTag": "\"773c68a216d984878d8f42327cd4d90e\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 64007
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200228-WA0059.jpg",
          "eTag": "\"2fa93c9217bbb8bae7223da2c1d605df\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 244849
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - WhatsApp Image 2020-02-08 at 8.39.24 PM.jpeg",
          "eTag": "\"ef32f9e52e3bc7470b1d2e9cdfcaeda9\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 127612
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - WhatsApp Image 2020-02-08 at 8.39.27 PM.jpeg",
          "eTag": "\"15189e2b16baa41d684df1a4c0d19f84\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 90887
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - WhatsApp Image 2020-02-18 at 6.20.52 PM (1).jpeg",
          "eTag": "\"739071daa52744f6c80eff1b2f3d9746\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 152475
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - WhatsApp Image 2020-02-20 at 5.27.35 PM.jpeg",
          "eTag": "\"50c4d4ce606cce5366b7713b5e5b9dca\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 72582
        },
        {
          "key": "pictures/DindigulEOL/commissioner Dindigul Corporation - WhatsApp Image 2020-02-08 at 8.39.21 PM (1).jpeg",
          "eTag": "\"176a50b3bc92646c4480a5cc4b5cb45d\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 107166
        }
      ]
    },
    {
      "city": "Erode",
      "images": [
        {
          "key": "pictures/ErodeEOL/Audio Announcement.png",
          "eTag": "\"779055f0c79c744696dbba836ad0c42b\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 85304
        },
        {
          "key": "pictures/ErodeEOL/Rally.png",
          "eTag": "\"84ea18f0467efbbcdbbc9fa3eed5114b\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 89942
        },
        {
          "key": "pictures/ErodeEOL/Webp.net-resizeimage.jpg",
          "eTag": "\"414cef7efef9e6238287af48302f4103\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 247083
        }
      ]
    },
    {
      "city": "Gangtok",
      "images": [
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - citizens participation 2.jpg",
          "eTag": "\"44ed199f60802b305d7d05c6e5b6241e\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 164836
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - citizens participation 3.jpg",
          "eTag": "\"f548fdd95736ee94451cf795a8c404a6\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 174574
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - citizens participation 5.jpg",
          "eTag": "\"63becb6f195753b6e4bb3a47941db6da\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 180189
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - commercial area 2.jpg",
          "eTag": "\"d5f389dba8e3c3d48c648a4f2f9261a1\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 79408
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - commercial area.jpeg",
          "eTag": "\"6d5e7faa529c4fd16eeeb36d72fb7049\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 86119
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - institutional area.jpeg",
          "eTag": "\"1bba9ee5634be79e19ad819853692291\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 91088
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - taxi stand.jpg",
          "eTag": "\"cc5bf5b3281488dca1cecd73ffc0a6c6\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 87560
        }
      ]
    },
    {
      "city": "Greater Mumbai",
      "images": [
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Auto stand hoarding.jpg",
          "eTag": "\"b23c0e0171c1dd796989a173f17b212c\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 154937
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Awarenes _ demo workshop with Mahila Bachat Gat 12.2.2020.jpg",
          "eTag": "\"699c6e39fec01ba9de31a875ce9ab7c5\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 130919
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Commercial area.jpg",
          "eTag": "\"c75ffbc4a4f96dcbec01164055585292\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 36837
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Demo Event @ Worli Engg. Hub building 7.2.20.jpg",
          "eTag": "\"feec164da214323eadcf3e4bbb18c42f\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 61119
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Educational area hoarding.jpg",
          "eTag": "\"57c56b9ee8594488a47d2fecc88a03ce\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 182436
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - F South Demo Workshop 2.jpg",
          "eTag": "\"d8b1ee52907975e517433c28ca93a1ca\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 118745
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - F South Demo Workshop.jpg",
          "eTag": "\"e59d298bdf850be8359ccb6ff6953326\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 117126
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Flyover hoarding.jpg",
          "eTag": "\"f266f0c3a792c2f1844bfc5a4e783d83\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 113090
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Fnorth Citizen Engagement workshop  2.jpg",
          "eTag": "\"03d731cb940eb85791751ebadfbc07c8\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 123778
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Large Market area.jpg",
          "eTag": "\"31f7dffdbb12183aff33c5021141c81b\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 183200
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Mumbai EOLI Stall @ Nehru Centre 7Feb - 9Feb 2020.jpg",
          "eTag": "\"dc02aeb07ff2f05a5c3d84232d6fcde4\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 63902
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Poster in Office building.jpg",
          "eTag": "\"152319834cc2eb141917bdd8ab63dabc\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 2350166
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - commercial area hoarding.jpg",
          "eTag": "\"73bfa4882d761454a71eb9fab9922940\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 191011
        }
      ]
    },
    {
      "city": "Guwahati",
      "images": [
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-01 at 10.32.41 AM.jpeg",
          "eTag": "\"76430f8a86a7200b870d9ffe24b15f20\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 145705
        },
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-06 at 11.35.56 AM.jpeg",
          "eTag": "\"70057e908d56d788fcf373ec6f43f64d\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 96896
        },
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-06 at 11.35.57 AM(1).jpeg",
          "eTag": "\"c94d703ae773fa9a94c39d864b8538fa\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 81817
        },
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-14 at 4.20.37 PM.jpeg",
          "eTag": "\"8f4bd345fd809d8e252b141870b29eb3\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 154587
        },
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-14 at 4.20.44 PM (1).jpeg",
          "eTag": "\"6613a338bae8ba31e09bd543cbd8a821\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 156179
        }
      ]
    },
    {
      "city": "Gwalior",
      "images": [
        {
          "key": "pictures/GwaliorEOL/24365f54-9339-49b6-b5be-2ea09feddc6f.jpg",
          "eTag": "\"b0576283a2ba68930e9b64e086eb2957\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 103388
        }
      ]
    },
    {
      "city": "Indore",
      "images": [
        {
          "key": "pictures/IndoreEOL/c3f9f709-f67f-4a5c-abf1-24b5fc801417_upscaled_image_x4.jpg",
          "eTag": "\"8d70d9dbf3150c3dd5dfa8a8a69448c3\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 5603070
        }
      ]
    },
    {
      "city": "Itanagar",
      "images": [
        {
          "key": "pictures/ItanagarEOL/WhatsApp Image 2020-03-20 at 12.26.34 PM.jpeg",
          "eTag": "\"2c38e674e386908bf156cd7f0cf530e2\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 104280
        },
        {
          "key": "pictures/ItanagarEOL/WhatsApp Image 2020-03-20 at 12.26.35 PM.jpeg",
          "eTag": "\"30a001ccdf8d54440f060b3dd190497c\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 109389
        },
        {
          "key": "pictures/ItanagarEOL/WhatsApp Image 2020-03-20 at 12.26.37 PM (1).jpeg",
          "eTag": "\"094028c6309b1788c2c48d10989914a6\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 202346
        },
        {
          "key": "pictures/ItanagarEOL/WhatsApp Image 2020-03-20 at 12.26.38 PM.jpeg",
          "eTag": "\"60e83b6e27756fbbe2e8a80176dfebae\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 190607
        }
      ]
    },
    {
      "city": "Jabalpur",
      "images": [
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - 2.jpg",
          "eTag": "\"f6999190fa2ffd283412d0e4aa5e513b\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 117118
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - 3.jpg",
          "eTag": "\"0f196adb5a7a804140fdd85e1385b11d\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 86286
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - 502a614c-835f-47ad-be0a-e7a54e1d47b0.jpg",
          "eTag": "\"d22f97aa0b270b541c571338a0e05538\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 147862
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - EoLawareness campaign.jpg",
          "eTag": "\"fcf214aeccf118acfd6ef7a35881f475\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 105763
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - Painting Competion.jpg",
          "eTag": "\"a6c9a1b746a90c15ec5ed7cd2a3bfcbf\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 2728270
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - WhatsApp Image 2020-02-28 at 12.35.39.jpeg",
          "eTag": "\"5e755f97c318647edf72d2c4daee6368\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 170532
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - WhatsApp Image 2020-02-28 at 12.35.43.jpeg",
          "eTag": "\"3035e5eac13135ad37d6b9e2bc87b9df\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 154966
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - awareness campaign with college students 18.02.2020.jpeg",
          "eTag": "\"b881054590cdbbbad6cf24c1a23cf6d1\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 133055
        }
      ]
    },
    {
      "city": "Jaipur",
      "images": [
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-14 at 12.05.13 PM.jpeg",
          "eTag": "\"7788d4f9e2ec195794b340a65ca92fd5\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 154907
        },
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-14 at 12.33.26 PM.jpeg",
          "eTag": "\"12aa4c6ad0dd70b356b13bc0497c1ad6\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 172626
        },
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-15 at 3.32.23 PM.jpeg",
          "eTag": "\"9dc118932dca98d9f4ae319731499486\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 184458
        },
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-15 at 3.32.26 PM.jpeg",
          "eTag": "\"8acce1c737be3e2f9abe769490f177dd\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 202326
        },
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-15 at 3.35.21 PM.jpeg",
          "eTag": "\"829ff70d548ab730858b945f8645e736\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 128334
        }
      ]
    },
    {
      "city": "Jalandhar",
      "images": [
        {
          "key": "pictures/JalandharEOL/surjit saini - 01-Jalandhar-Hoarding-15-02-20.jpg",
          "eTag": "\"a9f4c10c0fc1510e42831f5281cc5845\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 104653
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 03-Jalandhar-Hoarding-15-02-20.jpg",
          "eTag": "\"a0fe2af8297719764f607859a8eaca3a\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 103338
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 05-Jalandhar-Hoarding-20-02-20.jpg",
          "eTag": "\"852281cdaf69f4b69b06c4f5d641c598\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 118193
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 05-Jalandhar-Poster-26-02-20.jpg",
          "eTag": "\"a6135c6e91e79d14d81c4348b26b8ebe\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 69658
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 06-Jalandhar-Poster-26-02-20.jpg",
          "eTag": "\"22df67257c7a961fa5330beb7567c508\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 66980
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 07-Jalandhar-Poster-26-02-20.jpg",
          "eTag": "\"d2879f6c86cdac1c2658df8c4ea4382c\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 63451
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 08-Jalandhar-Poster-26-02-20.jpg",
          "eTag": "\"8af9dca88cb9122585c7267d73d1c189\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 92616
        }
      ]
    },
    {
      "city": "Jammu",
      "images": [
        {
          "key": "pictures/JammuEOL/Arun Kumar Shakar - AmarUlala Rate Your City.jpeg",
          "eTag": "\"461667a57fbe1660f5f3041a856df106\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 110313
        }
      ]
    },
    {
      "city": "Jodhpur",
      "images": [
        {
          "key": "pictures/JodhpurEOL/2020-03-23_1225.png",
          "eTag": "\"f26375166e62af04cd03c0c113afee33\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 308033
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Commercial Area 2_Banner_Jodhpur_07022020.jpg",
          "eTag": "\"35ad8ef673c504b39d9f4085b0f927b9\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 72192
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Flyover_Banner_Jodhpur_07022020.jpg",
          "eTag": "\"c1fd11fd348ffadafc000dcddfc7caa9\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 158213
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Large Market Area_Banner_Jodhpur_07022020.jpg",
          "eTag": "\"d52fe09da5fed3aeaace189c9f9b0795\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 101954
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Press Conference_07022020_Jodhpur(2).jpeg",
          "eTag": "\"27569d41075968c3c94c40deb85c9c63\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 124961
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Railway Station_Banner_Jodhpur_07022020.jpg",
          "eTag": "\"7614268be4fe3ffd64f9c19689814395\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 83181
        }
      ]
    },
    {
      "city": "Kota",
      "images": [
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 1.jpg",
          "eTag": "\"d12b76e86f881e3b45179465767dfcbf\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 106297
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 2.jpg",
          "eTag": "\"9db7316dfb90d43b28cc617717c43ff1\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 108695
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 3.jpg",
          "eTag": "\"994d4f1a8cbdb32d6d7fd305ff63b130\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 102295
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 4.jpg",
          "eTag": "\"78c0ed7d1b94662d5fa67b96fc850079\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 92634
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 5.jpg",
          "eTag": "\"6c8b108493f3bd0c944f0085bb33dad4\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 72670
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 6.jpg",
          "eTag": "\"ef7b23d4b6a673f852753ebcd717181e\"",
          "lastModified": "2021-03-02T07:17:43.000Z",
          "size": 109744
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 7.jpg",
          "eTag": "\"c831c6496410fa35261eb1ce3e240fdd\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 84641
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 8.jpg",
          "eTag": "\"167d141bb9de9babeaa6ef3c0cd96ace\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 101478
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 9.jpg",
          "eTag": "\"ae585df4569a95dc8dcd244e9da9b865\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 79688
        }
      ]
    },
    {
      "city": "Lucknow",
      "images": [
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 18.55.19.jpeg",
          "eTag": "\"b8c0d63355ef87aaa012e966d987b995\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 186061
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 18.55.42.jpeg",
          "eTag": "\"df3cf50ccf51f186f4cf093a6a735776\"",
          "lastModified": "2021-03-02T07:17:43.000Z",
          "size": 125307
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 18.56.44.jpeg",
          "eTag": "\"d1296ffaa3b6f3ebec52ae84eaa01c88\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 121226
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 18.57.06.jpeg",
          "eTag": "\"60a9b5066027bc7ff95a25b199fe5d8e\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 86888
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 19.04.53.jpeg",
          "eTag": "\"bdf111e60fba48ff4f8bdd36a33433e6\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 129882
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 19.07.17.jpeg",
          "eTag": "\"aa62cb42919810c63d3510a3a9bc563f\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 99773
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-07 at 17.30.52 (1).jpeg",
          "eTag": "\"b2ae851fdb362272cf9eec1eb7d4724d\"",
          "lastModified": "2021-03-02T07:17:43.000Z",
          "size": 91778
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-07 at 17.43.03.jpeg",
          "eTag": "\"029bc535341534ed33329dbcfba7444b\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 81859
        }
      ]
    },
    {
      "city": "Ludhiana",
      "images": [
        {
          "key": "pictures/LudhianaEOL/lakhvir singh - Ludhiana Smart City Installation  2-page-002.jpg",
          "eTag": "\"daedfaf6a0d3f5d9ba425269252b19de\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 328141
        },
        {
          "key": "pictures/LudhianaEOL/lakhvir singh - WhatsApp Image 2020-02-07 at 1.06.51 PM.jpeg",
          "eTag": "\"2f95e2c590dcb16c100d6b710f8b8d07\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 60085
        }
      ]
    },
    {
      "city": "Mangalore",
      "images": [
        {
          "key": "pictures/MangaloreEOL/Managalore 6.jpg",
          "eTag": "\"21ec3bb8aabd02a622871aad83a37a01\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 117462
        },
        {
          "key": "pictures/MangaloreEOL/Managalore.jpeg",
          "eTag": "\"8dbd294d75496fa38b0eccb6fa7fa2f3\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 85656
        },
        {
          "key": "pictures/MangaloreEOL/Managalore3.jpg",
          "eTag": "\"e28702b0cbd6b12724b57ca568b68072\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 156197
        },
        {
          "key": "pictures/MangaloreEOL/Mangalore 2.jpg",
          "eTag": "\"8103e5bcf23e98fc2327c960bf9182c1\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 89143
        },
        {
          "key": "pictures/MangaloreEOL/managalore 4.jpg",
          "eTag": "\"dd74cc46c23e721fc5c683cd0bf86e9e\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 88886
        },
        {
          "key": "pictures/MangaloreEOL/managalore 7.jpg",
          "eTag": "\"ea5541936b7055f42ae32f4447a17cc5\"",
          "lastModified": "2021-03-02T07:17:43.000Z",
          "size": 187064
        }
      ]
    },
    {
      "city": "Moradabad",
      "images": [
        {
          "key": "pictures/MoradabadEOL/ROHIT  KUMAR  - WhatsApp Image 2020-02-04 at 6.55.09 PM.jpeg",
          "eTag": "\"7c41d128811ae6523faa874ecea7067c\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 179452
        },
        {
          "key": "pictures/MoradabadEOL/ROHIT  KUMAR  - WhatsApp Image 2020-02-04 at 6.55.51 PM (1).jpeg",
          "eTag": "\"227c7280a1005ab5c946d5fc82128985\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 188129
        },
        {
          "key": "pictures/MoradabadEOL/ROHIT  KUMAR  - WhatsApp Image 2020-02-04 at 6.56.06 PM (1).jpeg",
          "eTag": "\"0bacc6656012eb9b838ff3fad5fa93b4\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 117664
        },
        {
          "key": "pictures/MoradabadEOL/ROHIT  KUMAR  - WhatsApp Image 2020-02-04 at 6.56.08 PM.jpeg",
          "eTag": "\"9acd78991a921dd50eb7213f77ad6623\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 180161
        }
      ]
    },
    {
      "city": "Prayagraj",
      "images": [
        {
          "key": "pictures/PrayagrajEOL/Mani Tripathi - 01.jpg",
          "eTag": "\"c8f352c1d2221f32f7de7a13086c1699\"",
          "lastModified": "2021-03-02T07:17:45.000Z",
          "size": 422570
        },
        {
          "key": "pictures/PrayagrajEOL/Mani Tripathi - 03.jpg",
          "eTag": "\"a0171a3a1bfbecaee88f936e32e6b024\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 334888
        },
        {
          "key": "pictures/PrayagrajEOL/Mani Tripathi - 05.jpg",
          "eTag": "\"78fa421db42dabbf3487bd6162e40413\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 292396
        },
        {
          "key": "pictures/PrayagrajEOL/Mani Tripathi - 07.jpg",
          "eTag": "\"91b7caaadb0d1287671d416957ea1442\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 233837
        }
      ]
    },
    {
      "city": "Pune",
      "images": [
        {
          "key": "pictures/PuneEOL/IMG-20200206-WA0053.jpg",
          "eTag": "\"30508272a86a1e13246beec31b081597\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 74519
        },
        {
          "key": "pictures/PuneEOL/IMG-20200206-WA0057.jpg",
          "eTag": "\"b73480d10ca6f51736495e0e754ed02e\"",
          "lastModified": "2021-03-02T07:17:45.000Z",
          "size": 86646
        },
        {
          "key": "pictures/PuneEOL/IMG-20200206-WA0059.jpg",
          "eTag": "\"e9412f8237819d4cd0f55ef9fc012a13\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 83914
        }
      ]
    },
    {
      "city": "Saharanpur",
      "images": [
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 0f60b925-6dc7-45f7-84f3-c5ad6a595603.jpg",
          "eTag": "\"670ca1d8f3bd117cc3502d29e380f7d1\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 148618
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 13852ea1-f997-496a-855d-b31b8db1e428.jpg",
          "eTag": "\"cfd5df77e930bab5618070668dca8e1e\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 118237
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 2ef98a59-f906-47dd-9e18-8d28f1b66ba8.jpg",
          "eTag": "\"3891a056dbd1dc2a352845628b5e396e\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 136995
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 3a978ebf-7c71-485b-ad1d-86b930bd8676.jpg",
          "eTag": "\"095170b3fc78354fc17021dea3cf5069\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 128683
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 4d69318f-258e-4196-abe5-123fa4a202ad.jpg",
          "eTag": "\"8409b26163d6c68e8d0b74a668a31a12\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 135958
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 4e238a93-1424-4e2f-b64a-817a20d51663.jpg",
          "eTag": "\"a8628e094e772a7cac277644e59b143d\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 115496
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 51c629ca-136f-42dc-bd26-ece618f7973f.jpg",
          "eTag": "\"4d0b8ad7e1012bea884c6dbd23d35f25\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 139395
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 5f3244f3-63b5-472d-84f4-3af004e88a3a.jpg",
          "eTag": "\"d9beca4148089a0ba5143e30002597d1\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 123902
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 6477bd29-1afd-448a-b035-4fc4b6215d73.jpg",
          "eTag": "\"cc553f413b9adfec6f85e935dfd59b4a\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 147900
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 79e03008-dfba-42ad-b832-fbd982a0f21c.jpg",
          "eTag": "\"560777b22aa034e899df76ee141fe41b\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 144233
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - eab52c84-3be3-401f-8eee-190e9f36546a.jpg",
          "eTag": "\"b6b26d209c90cd6551b2e2af0e8f8176\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 132826
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - eb67b4cc-794e-48cd-8291-7e96f2d9e1ea.jpg",
          "eTag": "\"9102a49a4133fafcafc9002bf3cce4ad\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 119948
        }
      ]
    },
    {
      "city": "Salem",
      "images": [
        {
          "key": "pictures/SalemEOL/Salem.jpeg",
          "eTag": "\"78c7521f95bc740a8ddd9f7052c18bc6\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 163802
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.06 PM (1).jpeg",
          "eTag": "\"fded3fbe4a0ff3ba8c97397d4157e81b\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 364729
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.06 PM (2).jpeg",
          "eTag": "\"6f106521026149cd78e5dc273b342a14\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 422000
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.06 PM.jpeg",
          "eTag": "\"fded3fbe4a0ff3ba8c97397d4157e81b\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 364729
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.07 PM (1).jpeg",
          "eTag": "\"8dec76962d4d22201ce42871cc461d81\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 389764
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.07 PM (2).jpeg",
          "eTag": "\"e5b1c6e8f183caba149cbef3418c24e2\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 419662
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.07 PM (3).jpeg",
          "eTag": "\"18c91a0758db8a778c18832d06bd0b91\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 531696
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.07 PM.jpeg",
          "eTag": "\"8dec76962d4d22201ce42871cc461d81\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 389764
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.08 PM (1).jpeg",
          "eTag": "\"cd303b2c994f70082d5a6fc48760f885\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 378119
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.08 PM.jpeg",
          "eTag": "\"053fe654fb24405381a95a57bc94c835\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 249239
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.09 PM (1).jpeg",
          "eTag": "\"f67d40f8d2d38e6801526ec352939f5e\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 394321
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.09 PM.jpeg",
          "eTag": "\"fdb1add2457384bcfde08221390be6aa\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 275535
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.10 PM (1).jpeg",
          "eTag": "\"bee377b2c33b3b46a3e1e2b05e9bd6d3\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 265537
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.10 PM.jpeg",
          "eTag": "\"ea931a2150b9b7d73a71dc00e3101279\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 399504
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 12.48.23 PM.jpeg",
          "eTag": "\"11136ba4007cbd17e2a3ac02ff8a8f0e\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 145054
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 12.48.25 PM.jpeg",
          "eTag": "\"89e69ce0a16df01d360a64f0a05b34d0\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 200876
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 12.48.28 PM.jpeg",
          "eTag": "\"4a8d0ea0a8d9834a5c1924d258cf2b06\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 196760
        }
      ]
    },
    {
      "city": "Satna",
      "images": [
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Busstand_Satna_24.02.2020 (2).jpg",
          "eTag": "\"5f53333cf2e5fde9f92f6bd219da457a\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 170134
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - College_24.02.2020 (2).jpg",
          "eTag": "\"5b6aa5987ced0b34aac0d55aa40aba7a\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 127001
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - College_Satna1_24.02.2020.jpg",
          "eTag": "\"4f633f7ec3424be10ea844c97601d613\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 68625
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - College_Satna3_24.02.2020.jpg",
          "eTag": "\"cc5adcd82e990a74d43f1f5c3fd117c3\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 100764
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Govtoffice_satna_15.02.2020.jpg",
          "eTag": "\"9648f6779abccaf5717b888974db6081\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 224048
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Park_Satna_24.02.2020.jpg",
          "eTag": "\"609ff77465464588085e8fbf0266e704\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 164629
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Satna_24.02 (1).jpg",
          "eTag": "\"6137a6c97f0308c46986cbf0feae3a70\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 142319
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Satna_24.02.2020.jpg",
          "eTag": "\"b22e4939260be99dc131185d3985f221\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 145548
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation10_Satna_29.02.2020.jpg",
          "eTag": "\"8812c174d773cbc246f0c6639159db43\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 16227286
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation2_Satna_29.02.2020.jpg",
          "eTag": "\"a8f01fd58146e8c0fecb05035bbc2593\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 12712969
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation5_Satna_29.02.2020.jpg",
          "eTag": "\"c69393589cb9bb4035a273fd63185876\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 14525979
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation6_Satna_29.02.2020.jpg",
          "eTag": "\"3eeb64795f36ff33370dfa960f245811\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 12611482
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation7_Satna_29.02.2020.jpg",
          "eTag": "\"66941c09d01b099b0dd5e7c043d953e2-2\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 18723870
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation9_Satna_29.02.2020.jpg",
          "eTag": "\"ae7e385e1f3a44d0b8cf4169ca0c9376\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 14139685
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - chauraha_satna_15.02.2020.jpg",
          "eTag": "\"78b8e06470502e9a00487332a4ec3ae2\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 179971
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - doortodooradvertisment_Satna_15.02.2020.jpg",
          "eTag": "\"26ec60cefdea0fe2d937e5d8667c7dfc\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 194250
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - ratecity images.jpg",
          "eTag": "\"07036b38b86ea122cc2648f60fb880cc\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 74996
        },
        {
          "key": "pictures/SatnaEOL/chandramani dwivedi - College_Satna2.jpg",
          "eTag": "\"80aa14e1919a883ef4b602b4ffd1d8ad\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 112518
        },
        {
          "key": "pictures/SatnaEOL/chandramani dwivedi - Starsmachar_02.02.2020.jpg",
          "eTag": "\"74b036ff6d1880f913c0e72267d3e928\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 124155
        }
      ]
    },
    {
      "city": "Solapur",
      "images": [
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200213-WA0069.jpg",
          "eTag": "\"8b4861d11a8029a26ba66c2d690fd49c\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 150096
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200213-WA0071.jpg",
          "eTag": "\"22fd5cc65f605b7d5dabdee03ec42a45\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 111700
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0056.jpg",
          "eTag": "\"c9714a6b87b94edb5c03f449641fd706\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 77801
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0057.jpg",
          "eTag": "\"096f9b228fa4d1db4e854a981055496f\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 68832
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0058.jpg",
          "eTag": "\"29347ba62fbd5504b54e8464fd180d6b\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 81133
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0059.jpg",
          "eTag": "\"9f0eac6e4447cfc7e2bede9acb7288f1\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 96038
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0060.jpg",
          "eTag": "\"5b0d10e3a2205ccf4be726bf89af0af1\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 158994
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200217-WA0022.jpg",
          "eTag": "\"ce58c8a5c5b20125a5f894079a5c2f4d\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 87893
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200225-WA0005.jpg",
          "eTag": "\"2d3d5f13f343e1c8d6df4f16f2cd406a\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 194653
        }
      ]
    },
    {
      "city": "Surat",
      "images": [
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.49 PM (1).jpeg",
          "eTag": "\"f01bf751a09dfc3cba8a3c592cf77f8a\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 106309
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.52 PM (2).jpeg",
          "eTag": "\"1cc62a93dbb7c5089e31383b8b92d8f9\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 96187
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.52 PM.jpeg",
          "eTag": "\"d3ffb332f403ddef2090f60964cef357\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 98019
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.53 PM (1).jpeg",
          "eTag": "\"906d4896ae9358a36bf52cac3fc540a2\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 91574
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.53 PM.jpeg",
          "eTag": "\"a277ed6e3f4b7a038e5b17fb7d4dc582\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 93114
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.28 PM (1).jpeg",
          "eTag": "\"d9545a8fe896eed3f8b44f5b148d463d\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 94502
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.29 PM (1).jpeg",
          "eTag": "\"9524164496167da1cadfb9e0a71a1c52\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 65485
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.29 PM (2).jpeg",
          "eTag": "\"c00c76b1eca25244e47057e8719990af\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 73520
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.29 PM.jpeg",
          "eTag": "\"09477628a1b5dd2ebc83e7eed3b7fe4b\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 78892
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.30 PM (1).jpeg",
          "eTag": "\"10c76f87fd6edac294dee1edd7ede938\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 56177
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.30 PM.jpeg",
          "eTag": "\"a092e17e170157a557a010f565953a82\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 101658
        }
      ]
    },
    {
      "city": "Ujjain",
      "images": [
        {
          "key": "pictures/UjjainEOL/61.jfif",
          "eTag": "\"70d12eda14e485e37d8a1f572febf0e5\"",
          "lastModified": "2021-03-02T07:17:51.000Z",
          "size": 157903
        },
        {
          "key": "pictures/UjjainEOL/WhatsApp Image 2020-03-23 at 5.25.33 PM.jpeg",
          "eTag": "\"8480537b4a712867d1d8df0f3f4da411\"",
          "lastModified": "2021-03-02T07:17:51.000Z",
          "size": 345218
        },
        {
          "key": "pictures/UjjainEOL/ujjain_school_eol_8.jpg",
          "eTag": "\"523ebf06ea13db480020a56ebd801951\"",
          "lastModified": "2021-03-02T07:17:51.000Z",
          "size": 241916
        }
      ]
    },
    {
      "city": "Aligarh",
      "images": [
        {
          "key": "pictures/AligarhEOL/Aligarh  Nagar Nigam - IMG-20200210-WA0138.jpg",
          "eTag": "\"24da9f7f95c292fd474f1ebf428d7134\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 203136
        },
        {
          "key": "pictures/AligarhEOL/Aligarh  Nagar Nigam - IMG-20200210-WA0140.jpg",
          "eTag": "\"939143fa0ced65f48b65fe7b27ca1124\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 215592
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200207-WA0048.jpg",
          "eTag": "\"931daff1428099fa7a40675905e52688\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 242619
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200209-WA0039.jpg",
          "eTag": "\"4663e803b97b0db38f22995f0965bd2e\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 204427
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200209-WA0049.jpg",
          "eTag": "\"013c7c0ddafd810a8d30882f767f3e8d\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 135341
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200209-WA0050.jpg",
          "eTag": "\"c1431b31f4b63d6cf20f4c4ff27c4800\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 134939
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200210-WA0141.jpg",
          "eTag": "\"356b08b9094e79bc4fa9b5cb06710580\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 134827
        },
        {
          "key": "pictures/AligarhEOL/Aligarh Nagar Nigam - IMG-20200218-WA0073.jpg",
          "eTag": "\"8239c1bf0078cf7e4e11907c30a8f79a\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 255443
        }
      ]
    },
    {
      "city": "Agartala",
      "images": [
        {
          "key": "pictures/AgartalaEOL/FotoJet.jpg",
          "eTag": "\"43402384654c3e13b15ef657836aaf4d\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 402575
        },
        {
          "key": "pictures/AgartalaEOL/Public consultation 2.jfif",
          "eTag": "\"7272b34a0c6693bf676579fe1cd91f3c\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 105006
        },
        {
          "key": "pictures/AgartalaEOL/Public consultation 3.jfif",
          "eTag": "\"2c24e2c5c548dfbe7fd9354666ecb8b0\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 133660
        },
        {
          "key": "pictures/AgartalaEOL/Public consultation 4.jfif",
          "eTag": "\"3b98d57bc7d13eff7940a8c081e7901f\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 143028
        },
        {
          "key": "pictures/AgartalaEOL/Reshmi  Ghosh  - 1.jfif",
          "eTag": "\"5ac012b1e1d2ebe88c09d6835d2b0549\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 234398
        },
        {
          "key": "pictures/AgartalaEOL/v_Website+Update_upscaled_image_x4.jpg",
          "eTag": "\"03556b23f518236d966b43dbc3b5aa85\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 3295755
        }
      ]
    },
    {
      "city": "Ajmer",
      "images": [
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (13).jpeg",
          "eTag": "\"963fd2e8d1b8e1c3ab46ad88146343c4\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 169168
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (14).jpeg",
          "eTag": "\"e78ab5b3ab9ae6e0ded94ef78fcf6b0e\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 236328
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (15).jpeg",
          "eTag": "\"83f86d60d22cf8507ce9f08823a21e8a\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 88574
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (2).jpeg",
          "eTag": "\"42402ebc22eb7a440ab3ee6b16efd3f1\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 244284
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (3).jpeg",
          "eTag": "\"538a3a0f644a08573cdd9d3fda11c8b2\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 239933
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (4).jpeg",
          "eTag": "\"676b525652abf013cb3d44cf7d1560ce\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 271610
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (5).jpeg",
          "eTag": "\"9283682453212fb93b5d7221c9d04e0e\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 188517
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (6).jpeg",
          "eTag": "\"38a981b55761981e5bc5fc91d43700d6\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 260459
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (7).jpeg",
          "eTag": "\"108feab8d4e02042baa9ad851e0b251d\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 203731
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Ajmer 24.02.2020 (9).jpeg",
          "eTag": "\"ef78e968699e37e35d63588ea1bb5cf9\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 199354
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Collectorate office Ajmer.jpeg",
          "eTag": "\"4a49f1ff2aabbf6e49e3f12d7d6de285\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 241946
        },
        {
          "key": "pictures/AjmerEOL/Harish Chawla - Near Ajmer Bus Stand.jpeg",
          "eTag": "\"f18a687f1c377c0428a4a70ff2c144b6\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 171722
        }
      ]
    },
    {
      "city": "Amravati",
      "images": [
        {
          "key": "pictures/AmravatiEOL/Amravati Municipal Corporation - WhatsApp Image 2020-02-09 at 4.30.31 PM(1).jpeg",
          "eTag": "\"95033cc2202a191678743fd5b20802f1\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 98570
        },
        {
          "key": "pictures/AmravatiEOL/Amravati Municipal Corporation - WhatsApp Image 2020-02-09 at 4.30.31 PM.jpeg",
          "eTag": "\"87c08caa6e92e64578418638fea32d2c\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 134049
        },
        {
          "key": "pictures/AmravatiEOL/Amravati Municipal Corporation - WhatsApp Image 2020-02-09 at 4.30.35 PM.jpeg",
          "eTag": "\"812444a54f71d143bdbd3ae6fef21f81\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 93176
        }
      ]
    },
    {
      "city": "Aurangabad",
      "images": [
        {
          "key": "pictures/AurangabadEOL/Abdul Baari - Aurangabad - Aurangpura Sq. 2 BQS (3).jpeg",
          "eTag": "\"7a321104a4d0461c92ad61be89965806\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 151053
        },
        {
          "key": "pictures/AurangabadEOL/Abdul Baari - Aurangabad - Cidco Chwok BQS (1).jpeg",
          "eTag": "\"a199a30d4997d71d63cad0252ec74c42\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 115234
        },
        {
          "key": "pictures/AurangabadEOL/Abdul Baari - Aurangabad - Cidco Chwok BQS (3).jpeg",
          "eTag": "\"e37319a487696b5b5f4725a1bb38e1e9\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 167734
        }
      ]
    },
    {
      "city": "Aizawl",
      "images": [
        {
          "key": "pictures/AizawlEOL/Lalramliana Varte - Aizawl 28 Feb Hoardings (2).jpg",
          "eTag": "\"659d6ffe2693be1b18bd18283b294bb6\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 242409
        },
        {
          "key": "pictures/AizawlEOL/Lalramliana Varte - Aizawl 28 Feb Hoardings (3).jpg",
          "eTag": "\"f3e0edd8726b2b2ab6ccdc9a0e3340c2\"",
          "lastModified": "2021-03-02T07:17:37.000Z",
          "size": 253157
        }
      ]
    },
    {
      "city": "Barielly",
      "images": [
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-12 at 10.00.24 PM.jpeg",
          "eTag": "\"702a25fdf0f6e1f1789f98e9ab880e8b\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 100956
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 11.35.42 AM.jpeg",
          "eTag": "\"16c90105d1104276ec510e190cafe69e\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 129248
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 12.37.22 PM.jpeg",
          "eTag": "\"dbfb3bcb9b725ed76c2d2be3309d99de\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 170662
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 12.43.43 PM.jpeg",
          "eTag": "\"9d9ad0cf714d449cadbf12857a496e33\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 136110
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 4.51.06 PM (1).jpeg",
          "eTag": "\"6d3c75d59a6c1e0f3d1b9e0ed1ffedfd\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 195311
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-13 at 4.51.49 PM (1).jpeg",
          "eTag": "\"a18e4056c5472ab14e8b1663d0d9614f\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 138256
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-14 at 4.04.35 PM.jpeg",
          "eTag": "\"35fe287a5fc4248944628abbf793b432\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 93382
        },
        {
          "key": "pictures/BariellyEOL/dileep  kumar - WhatsApp Image 2020-02-14 at 4.06.01 PM.jpeg",
          "eTag": "\"8d3bba3f9deac01b8686483231cd6704\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 103806
        }
      ]
    },
    {
      "city": "Belagavi",
      "images": [
        {
          "key": "pictures/BelagaviEOL/Belagavi Smartcity - WhatsApp Image 2020-02-11 at 12.59.41 PM.jpeg",
          "eTag": "\"8300bddca37ba374bd49e69f0527dd00\"",
          "lastModified": "2021-03-02T07:17:38.000Z",
          "size": 198199
        }
      ]
    },
    {
      "city": "Chennai",
      "images": [
        {
          "key": "pictures/ChennaiEOL/IEC MATERIAL/WhatsApp Image 2020-03-23 at 10.26.44 AM (1).jpeg",
          "eTag": "\"24ca01a5456cf12a3e7f0cfe341ad3e3\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 104842
        },
        {
          "key": "pictures/ChennaiEOL/IEC MATERIAL/WhatsApp Image 2020-03-23 at 10.26.44 AM.jpeg",
          "eTag": "\"5cc76caac1bea6d90cb8969aa8a07487\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 170640
        },
        {
          "key": "pictures/ChennaiEOL/IEC MATERIAL/WhatsApp Image 2020-03-23 at 10.26.45 AM.jpeg",
          "eTag": "\"06be4fc6a3d01de4da1341a771499177\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 121551
        }
      ]
    },
    {
      "city": "Dahod",
      "images": [
        {
          "key": "pictures/DahodEOL/IT Head - 2.jpeg",
          "eTag": "\"835a305c35a644117c19081fd6f71207\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 93340
        },
        {
          "key": "pictures/DahodEOL/IT Head - 3.jpeg",
          "eTag": "\"fd1bb532d2731574385c82857b7d9cd1\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 111852
        },
        {
          "key": "pictures/DahodEOL/IT Head - 5.jpeg",
          "eTag": "\"51bde7a0245525b5322bbfae30e51e01\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 122945
        },
        {
          "key": "pictures/DahodEOL/IT Head - Govindnagar.jpeg",
          "eTag": "\"ab40a99bc87cf690019391bf9217be9b\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 91429
        },
        {
          "key": "pictures/DahodEOL/IT Head - PadavCircle.jpeg",
          "eTag": "\"ec0b11c2a02d814fcd038c4db956e23f\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 124450
        }
      ]
    },
    {
      "city": "Dehradun",
      "images": [
        {
          "key": "pictures/DehradunEOL/WhatsApp Image 2020-03-19 at 18.16.30.jpeg",
          "eTag": "\"3b79994290c51b45f0497f8aafa2b28b\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 146111
        }
      ]
    },
    {
      "city": "Davangere",
      "images": [
        {
          "key": "pictures/DavangereEOL/CHANDRASHEKAR  S K - Press Meet 2020-02-26.jpeg",
          "eTag": "\"c125e517439f341a922b199b6387b351\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 145415
        },
        {
          "key": "pictures/DavangereEOL/CHANDRASHEKAR  S K - Press meet 2020-02-26 (2).jpeg",
          "eTag": "\"2b3d3680d41da6b038a52b690f238c89\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 75553
        }
      ]
    },
    {
      "city": "Dindigul",
      "images": [
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200219-WA0049.jpg",
          "eTag": "\"b5ea76baa85a04c175efc53d47b96863\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 65346
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200219-WA0067.jpg",
          "eTag": "\"516ed433b22f36e43ed6e0d383d68cac\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 66362
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200219-WA0133.jpg",
          "eTag": "\"50858eb5d813710449571a718fd06173\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 126629
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200220-WA0049.jpg",
          "eTag": "\"6fbaa4c24f6c7116a9df9b5f6c75e3cd\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 78332
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200220-WA0095.jpg",
          "eTag": "\"342521cbdb302532ff6278bd84ff836f\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 178098
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200220-WA0097.jpg",
          "eTag": "\"3eddad20e989507b12382c81586765d7\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 224738
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200228-WA0046.jpg",
          "eTag": "\"773c68a216d984878d8f42327cd4d90e\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 64007
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - IMG-20200228-WA0059.jpg",
          "eTag": "\"2fa93c9217bbb8bae7223da2c1d605df\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 244849
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - WhatsApp Image 2020-02-08 at 8.39.24 PM.jpeg",
          "eTag": "\"ef32f9e52e3bc7470b1d2e9cdfcaeda9\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 127612
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - WhatsApp Image 2020-02-08 at 8.39.27 PM.jpeg",
          "eTag": "\"15189e2b16baa41d684df1a4c0d19f84\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 90887
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - WhatsApp Image 2020-02-18 at 6.20.52 PM (1).jpeg",
          "eTag": "\"739071daa52744f6c80eff1b2f3d9746\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 152475
        },
        {
          "key": "pictures/DindigulEOL/Commissioner Dindigul Corporation - WhatsApp Image 2020-02-20 at 5.27.35 PM.jpeg",
          "eTag": "\"50c4d4ce606cce5366b7713b5e5b9dca\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 72582
        },
        {
          "key": "pictures/DindigulEOL/commissioner Dindigul Corporation - WhatsApp Image 2020-02-08 at 8.39.21 PM (1).jpeg",
          "eTag": "\"176a50b3bc92646c4480a5cc4b5cb45d\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 107166
        }
      ]
    },
    {
      "city": "Erode",
      "images": [
        {
          "key": "pictures/ErodeEOL/Audio Announcement.png",
          "eTag": "\"779055f0c79c744696dbba836ad0c42b\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 85304
        },
        {
          "key": "pictures/ErodeEOL/Rally.png",
          "eTag": "\"84ea18f0467efbbcdbbc9fa3eed5114b\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 89942
        },
        {
          "key": "pictures/ErodeEOL/Webp.net-resizeimage.jpg",
          "eTag": "\"414cef7efef9e6238287af48302f4103\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 247083
        }
      ]
    },
    {
      "city": "Gangtok",
      "images": [
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - citizens participation 2.jpg",
          "eTag": "\"44ed199f60802b305d7d05c6e5b6241e\"",
          "lastModified": "2021-03-02T07:17:39.000Z",
          "size": 164836
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - citizens participation 3.jpg",
          "eTag": "\"f548fdd95736ee94451cf795a8c404a6\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 174574
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - citizens participation 5.jpg",
          "eTag": "\"63becb6f195753b6e4bb3a47941db6da\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 180189
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - commercial area 2.jpg",
          "eTag": "\"d5f389dba8e3c3d48c648a4f2f9261a1\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 79408
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - commercial area.jpeg",
          "eTag": "\"6d5e7faa529c4fd16eeeb36d72fb7049\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 86119
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - institutional area.jpeg",
          "eTag": "\"1bba9ee5634be79e19ad819853692291\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 91088
        },
        {
          "key": "pictures/GangtokEOL/jimila  Shenga - taxi stand.jpg",
          "eTag": "\"cc5bf5b3281488dca1cecd73ffc0a6c6\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 87560
        }
      ]
    },
    {
      "city": "Greater Mumbai",
      "images": [
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Auto stand hoarding.jpg",
          "eTag": "\"b23c0e0171c1dd796989a173f17b212c\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 154937
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Awarenes _ demo workshop with Mahila Bachat Gat 12.2.2020.jpg",
          "eTag": "\"699c6e39fec01ba9de31a875ce9ab7c5\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 130919
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Commercial area.jpg",
          "eTag": "\"c75ffbc4a4f96dcbec01164055585292\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 36837
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Demo Event @ Worli Engg. Hub building 7.2.20.jpg",
          "eTag": "\"feec164da214323eadcf3e4bbb18c42f\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 61119
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Educational area hoarding.jpg",
          "eTag": "\"57c56b9ee8594488a47d2fecc88a03ce\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 182436
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - F South Demo Workshop 2.jpg",
          "eTag": "\"d8b1ee52907975e517433c28ca93a1ca\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 118745
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - F South Demo Workshop.jpg",
          "eTag": "\"e59d298bdf850be8359ccb6ff6953326\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 117126
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Flyover hoarding.jpg",
          "eTag": "\"f266f0c3a792c2f1844bfc5a4e783d83\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 113090
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Fnorth Citizen Engagement workshop  2.jpg",
          "eTag": "\"03d731cb940eb85791751ebadfbc07c8\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 123778
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Large Market area.jpg",
          "eTag": "\"31f7dffdbb12183aff33c5021141c81b\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 183200
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Mumbai EOLI Stall @ Nehru Centre 7Feb - 9Feb 2020.jpg",
          "eTag": "\"dc02aeb07ff2f05a5c3d84232d6fcde4\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 63902
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - Poster in Office building.jpg",
          "eTag": "\"152319834cc2eb141917bdd8ab63dabc\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 2350166
        },
        {
          "key": "pictures/Greater-MumbaiEOL/Greater Mumbai Nodal Officer - commercial area hoarding.jpg",
          "eTag": "\"73bfa4882d761454a71eb9fab9922940\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 191011
        }
      ]
    },
    {
      "city": "Guwahati",
      "images": [
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-01 at 10.32.41 AM.jpeg",
          "eTag": "\"76430f8a86a7200b870d9ffe24b15f20\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 145705
        },
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-06 at 11.35.56 AM.jpeg",
          "eTag": "\"70057e908d56d788fcf373ec6f43f64d\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 96896
        },
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-06 at 11.35.57 AM(1).jpeg",
          "eTag": "\"c94d703ae773fa9a94c39d864b8538fa\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 81817
        },
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-14 at 4.20.37 PM.jpeg",
          "eTag": "\"8f4bd345fd809d8e252b141870b29eb3\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 154587
        },
        {
          "key": "pictures/GuwahatiEOL/Priyangshu Das - WhatsApp Image 2020-02-14 at 4.20.44 PM (1).jpeg",
          "eTag": "\"6613a338bae8ba31e09bd543cbd8a821\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 156179
        }
      ]
    },
    {
      "city": "Gwalior",
      "images": [
        {
          "key": "pictures/GwaliorEOL/24365f54-9339-49b6-b5be-2ea09feddc6f.jpg",
          "eTag": "\"b0576283a2ba68930e9b64e086eb2957\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 103388
        }
      ]
    },
    {
      "city": "Indore",
      "images": [
        {
          "key": "pictures/IndoreEOL/c3f9f709-f67f-4a5c-abf1-24b5fc801417_upscaled_image_x4.jpg",
          "eTag": "\"8d70d9dbf3150c3dd5dfa8a8a69448c3\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 5603070
        }
      ]
    },
    {
      "city": "Itanagar",
      "images": [
        {
          "key": "pictures/ItanagarEOL/WhatsApp Image 2020-03-20 at 12.26.34 PM.jpeg",
          "eTag": "\"2c38e674e386908bf156cd7f0cf530e2\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 104280
        },
        {
          "key": "pictures/ItanagarEOL/WhatsApp Image 2020-03-20 at 12.26.35 PM.jpeg",
          "eTag": "\"30a001ccdf8d54440f060b3dd190497c\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 109389
        },
        {
          "key": "pictures/ItanagarEOL/WhatsApp Image 2020-03-20 at 12.26.37 PM (1).jpeg",
          "eTag": "\"094028c6309b1788c2c48d10989914a6\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 202346
        },
        {
          "key": "pictures/ItanagarEOL/WhatsApp Image 2020-03-20 at 12.26.38 PM.jpeg",
          "eTag": "\"60e83b6e27756fbbe2e8a80176dfebae\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 190607
        }
      ]
    },
    {
      "city": "Jabalpur",
      "images": [
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - 2.jpg",
          "eTag": "\"f6999190fa2ffd283412d0e4aa5e513b\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 117118
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - 3.jpg",
          "eTag": "\"0f196adb5a7a804140fdd85e1385b11d\"",
          "lastModified": "2021-03-02T07:17:40.000Z",
          "size": 86286
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - 502a614c-835f-47ad-be0a-e7a54e1d47b0.jpg",
          "eTag": "\"d22f97aa0b270b541c571338a0e05538\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 147862
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - EoLawareness campaign.jpg",
          "eTag": "\"fcf214aeccf118acfd6ef7a35881f475\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 105763
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - Painting Competion.jpg",
          "eTag": "\"a6c9a1b746a90c15ec5ed7cd2a3bfcbf\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 2728270
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - WhatsApp Image 2020-02-28 at 12.35.39.jpeg",
          "eTag": "\"5e755f97c318647edf72d2c4daee6368\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 170532
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - WhatsApp Image 2020-02-28 at 12.35.43.jpeg",
          "eTag": "\"3035e5eac13135ad37d6b9e2bc87b9df\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 154966
        },
        {
          "key": "pictures/JabalpurEOL/Ankur Khare - awareness campaign with college students 18.02.2020.jpeg",
          "eTag": "\"b881054590cdbbbad6cf24c1a23cf6d1\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 133055
        }
      ]
    },
    {
      "city": "Jalandhar",
      "images": [
        {
          "key": "pictures/JalandharEOL/surjit saini - 01-Jalandhar-Hoarding-15-02-20.jpg",
          "eTag": "\"a9f4c10c0fc1510e42831f5281cc5845\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 104653
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 03-Jalandhar-Hoarding-15-02-20.jpg",
          "eTag": "\"a0fe2af8297719764f607859a8eaca3a\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 103338
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 05-Jalandhar-Hoarding-20-02-20.jpg",
          "eTag": "\"852281cdaf69f4b69b06c4f5d641c598\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 118193
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 05-Jalandhar-Poster-26-02-20.jpg",
          "eTag": "\"a6135c6e91e79d14d81c4348b26b8ebe\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 69658
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 06-Jalandhar-Poster-26-02-20.jpg",
          "eTag": "\"22df67257c7a961fa5330beb7567c508\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 66980
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 07-Jalandhar-Poster-26-02-20.jpg",
          "eTag": "\"d2879f6c86cdac1c2658df8c4ea4382c\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 63451
        },
        {
          "key": "pictures/JalandharEOL/surjit saini - 08-Jalandhar-Poster-26-02-20.jpg",
          "eTag": "\"8af9dca88cb9122585c7267d73d1c189\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 92616
        }
      ]
    },
    {
      "city": "Jammu",
      "images": [
        {
          "key": "pictures/JammuEOL/Arun Kumar Shakar - AmarUlala Rate Your City.jpeg",
          "eTag": "\"461667a57fbe1660f5f3041a856df106\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 110313
        }
      ]
    },
    {
      "city": "Jodhpur",
      "images": [
        {
          "key": "pictures/JodhpurEOL/2020-03-23_1225.png",
          "eTag": "\"f26375166e62af04cd03c0c113afee33\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 308033
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Commercial Area 2_Banner_Jodhpur_07022020.jpg",
          "eTag": "\"35ad8ef673c504b39d9f4085b0f927b9\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 72192
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Flyover_Banner_Jodhpur_07022020.jpg",
          "eTag": "\"c1fd11fd348ffadafc000dcddfc7caa9\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 158213
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Large Market Area_Banner_Jodhpur_07022020.jpg",
          "eTag": "\"d52fe09da5fed3aeaace189c9f9b0795\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 101954
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Press Conference_07022020_Jodhpur(2).jpeg",
          "eTag": "\"27569d41075968c3c94c40deb85c9c63\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 124961
        },
        {
          "key": "pictures/JodhpurEOL/Alok Mathur - Railway Station_Banner_Jodhpur_07022020.jpg",
          "eTag": "\"7614268be4fe3ffd64f9c19689814395\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 83181
        }
      ]
    },
    {
      "city": "Jaipur",
      "images": [
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-14 at 12.05.13 PM.jpeg",
          "eTag": "\"7788d4f9e2ec195794b340a65ca92fd5\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 154907
        },
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-14 at 12.33.26 PM.jpeg",
          "eTag": "\"12aa4c6ad0dd70b356b13bc0497c1ad6\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 172626
        },
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-15 at 3.32.23 PM.jpeg",
          "eTag": "\"9dc118932dca98d9f4ae319731499486\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 184458
        },
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-15 at 3.32.26 PM.jpeg",
          "eTag": "\"8acce1c737be3e2f9abe769490f177dd\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 202326
        },
        {
          "key": "pictures/JaipurEOL/JAIPUR CITYPLAN DISATER MANAGEMNT - WhatsApp Image 2020-02-15 at 3.35.21 PM.jpeg",
          "eTag": "\"829ff70d548ab730858b945f8645e736\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 128334
        }
      ]
    },
    {
      "city": "Kota",
      "images": [
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 1.jpg",
          "eTag": "\"d12b76e86f881e3b45179465767dfcbf\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 106297
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 2.jpg",
          "eTag": "\"9db7316dfb90d43b28cc617717c43ff1\"",
          "lastModified": "2021-03-02T07:17:41.000Z",
          "size": 108695
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 3.jpg",
          "eTag": "\"994d4f1a8cbdb32d6d7fd305ff63b130\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 102295
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 4.jpg",
          "eTag": "\"78c0ed7d1b94662d5fa67b96fc850079\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 92634
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 5.jpg",
          "eTag": "\"6c8b108493f3bd0c944f0085bb33dad4\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 72670
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 6.jpg",
          "eTag": "\"ef7b23d4b6a673f852753ebcd717181e\"",
          "lastModified": "2021-03-02T07:17:43.000Z",
          "size": 109744
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 7.jpg",
          "eTag": "\"c831c6496410fa35261eb1ce3e240fdd\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 84641
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 8.jpg",
          "eTag": "\"167d141bb9de9babeaa6ef3c0cd96ace\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 101478
        },
        {
          "key": "pictures/KotaEOL/Nagar Nigam kota - 9.jpg",
          "eTag": "\"ae585df4569a95dc8dcd244e9da9b865\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 79688
        }
      ]
    },
    {
      "city": "Lucknow",
      "images": [
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 18.55.19.jpeg",
          "eTag": "\"b8c0d63355ef87aaa012e966d987b995\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 186061
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 18.55.42.jpeg",
          "eTag": "\"df3cf50ccf51f186f4cf093a6a735776\"",
          "lastModified": "2021-03-02T07:17:43.000Z",
          "size": 125307
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 18.56.44.jpeg",
          "eTag": "\"d1296ffaa3b6f3ebec52ae84eaa01c88\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 121226
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 18.57.06.jpeg",
          "eTag": "\"60a9b5066027bc7ff95a25b199fe5d8e\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 86888
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 19.04.53.jpeg",
          "eTag": "\"bdf111e60fba48ff4f8bdd36a33433e6\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 129882
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-06 at 19.07.17.jpeg",
          "eTag": "\"aa62cb42919810c63d3510a3a9bc563f\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 99773
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-07 at 17.30.52 (1).jpeg",
          "eTag": "\"b2ae851fdb362272cf9eec1eb7d4724d\"",
          "lastModified": "2021-03-02T07:17:43.000Z",
          "size": 91778
        },
        {
          "key": "pictures/LucknowEOL/Kapil  Sharma - WhatsApp Image 2020-02-07 at 17.43.03.jpeg",
          "eTag": "\"029bc535341534ed33329dbcfba7444b\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 81859
        }
      ]
    },
    {
      "city": "Ludhiana",
      "images": [
        {
          "key": "pictures/LudhianaEOL/lakhvir singh - Ludhiana Smart City Installation  2-page-002.jpg",
          "eTag": "\"daedfaf6a0d3f5d9ba425269252b19de\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 328141
        },
        {
          "key": "pictures/LudhianaEOL/lakhvir singh - WhatsApp Image 2020-02-07 at 1.06.51 PM.jpeg",
          "eTag": "\"2f95e2c590dcb16c100d6b710f8b8d07\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 60085
        }
      ]
    },
    {
      "city": "Mangalore",
      "images": [
        {
          "key": "pictures/MangaloreEOL/Managalore 6.jpg",
          "eTag": "\"21ec3bb8aabd02a622871aad83a37a01\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 117462
        },
        {
          "key": "pictures/MangaloreEOL/Managalore.jpeg",
          "eTag": "\"8dbd294d75496fa38b0eccb6fa7fa2f3\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 85656
        },
        {
          "key": "pictures/MangaloreEOL/Managalore3.jpg",
          "eTag": "\"e28702b0cbd6b12724b57ca568b68072\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 156197
        },
        {
          "key": "pictures/MangaloreEOL/Mangalore 2.jpg",
          "eTag": "\"8103e5bcf23e98fc2327c960bf9182c1\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 89143
        },
        {
          "key": "pictures/MangaloreEOL/managalore 4.jpg",
          "eTag": "\"dd74cc46c23e721fc5c683cd0bf86e9e\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 88886
        },
        {
          "key": "pictures/MangaloreEOL/managalore 7.jpg",
          "eTag": "\"ea5541936b7055f42ae32f4447a17cc5\"",
          "lastModified": "2021-03-02T07:17:43.000Z",
          "size": 187064
        }
      ]
    },
    {
      "city": "Moradabad",
      "images": [
        {
          "key": "pictures/MoradabadEOL/ROHIT  KUMAR  - WhatsApp Image 2020-02-04 at 6.55.09 PM.jpeg",
          "eTag": "\"7c41d128811ae6523faa874ecea7067c\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 179452
        },
        {
          "key": "pictures/MoradabadEOL/ROHIT  KUMAR  - WhatsApp Image 2020-02-04 at 6.55.51 PM (1).jpeg",
          "eTag": "\"227c7280a1005ab5c946d5fc82128985\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 188129
        },
        {
          "key": "pictures/MoradabadEOL/ROHIT  KUMAR  - WhatsApp Image 2020-02-04 at 6.56.06 PM (1).jpeg",
          "eTag": "\"0bacc6656012eb9b838ff3fad5fa93b4\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 117664
        },
        {
          "key": "pictures/MoradabadEOL/ROHIT  KUMAR  - WhatsApp Image 2020-02-04 at 6.56.08 PM.jpeg",
          "eTag": "\"9acd78991a921dd50eb7213f77ad6623\"",
          "lastModified": "2021-03-02T07:17:42.000Z",
          "size": 180161
        }
      ]
    },
    {
      "city": "Prayagraj",
      "images": [
        {
          "key": "pictures/PrayagrajEOL/Mani Tripathi - 01.jpg",
          "eTag": "\"c8f352c1d2221f32f7de7a13086c1699\"",
          "lastModified": "2021-03-02T07:17:45.000Z",
          "size": 422570
        },
        {
          "key": "pictures/PrayagrajEOL/Mani Tripathi - 03.jpg",
          "eTag": "\"a0171a3a1bfbecaee88f936e32e6b024\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 334888
        },
        {
          "key": "pictures/PrayagrajEOL/Mani Tripathi - 05.jpg",
          "eTag": "\"78fa421db42dabbf3487bd6162e40413\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 292396
        },
        {
          "key": "pictures/PrayagrajEOL/Mani Tripathi - 07.jpg",
          "eTag": "\"91b7caaadb0d1287671d416957ea1442\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 233837
        }
      ]
    },
    {
      "city": "Pune",
      "images": [
        {
          "key": "pictures/PuneEOL/IMG-20200206-WA0053.jpg",
          "eTag": "\"30508272a86a1e13246beec31b081597\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 74519
        },
        {
          "key": "pictures/PuneEOL/IMG-20200206-WA0057.jpg",
          "eTag": "\"b73480d10ca6f51736495e0e754ed02e\"",
          "lastModified": "2021-03-02T07:17:45.000Z",
          "size": 86646
        },
        {
          "key": "pictures/PuneEOL/IMG-20200206-WA0059.jpg",
          "eTag": "\"e9412f8237819d4cd0f55ef9fc012a13\"",
          "lastModified": "2021-03-02T07:17:46.000Z",
          "size": 83914
        }
      ]
    },
    {
      "city": "Saharanpur",
      "images": [
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 0f60b925-6dc7-45f7-84f3-c5ad6a595603.jpg",
          "eTag": "\"670ca1d8f3bd117cc3502d29e380f7d1\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 148618
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 13852ea1-f997-496a-855d-b31b8db1e428.jpg",
          "eTag": "\"cfd5df77e930bab5618070668dca8e1e\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 118237
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 2ef98a59-f906-47dd-9e18-8d28f1b66ba8.jpg",
          "eTag": "\"3891a056dbd1dc2a352845628b5e396e\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 136995
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 3a978ebf-7c71-485b-ad1d-86b930bd8676.jpg",
          "eTag": "\"095170b3fc78354fc17021dea3cf5069\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 128683
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 4d69318f-258e-4196-abe5-123fa4a202ad.jpg",
          "eTag": "\"8409b26163d6c68e8d0b74a668a31a12\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 135958
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 4e238a93-1424-4e2f-b64a-817a20d51663.jpg",
          "eTag": "\"a8628e094e772a7cac277644e59b143d\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 115496
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 51c629ca-136f-42dc-bd26-ece618f7973f.jpg",
          "eTag": "\"4d0b8ad7e1012bea884c6dbd23d35f25\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 139395
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 5f3244f3-63b5-472d-84f4-3af004e88a3a.jpg",
          "eTag": "\"d9beca4148089a0ba5143e30002597d1\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 123902
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 6477bd29-1afd-448a-b035-4fc4b6215d73.jpg",
          "eTag": "\"cc553f413b9adfec6f85e935dfd59b4a\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 147900
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - 79e03008-dfba-42ad-b832-fbd982a0f21c.jpg",
          "eTag": "\"560777b22aa034e899df76ee141fe41b\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 144233
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - eab52c84-3be3-401f-8eee-190e9f36546a.jpg",
          "eTag": "\"b6b26d209c90cd6551b2e2af0e8f8176\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 132826
        },
        {
          "key": "pictures/SaharanpurEOL/vaneet kumar birdi - eb67b4cc-794e-48cd-8291-7e96f2d9e1ea.jpg",
          "eTag": "\"9102a49a4133fafcafc9002bf3cce4ad\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 119948
        }
      ]
    },
    {
      "city": "Salem",
      "images": [
        {
          "key": "pictures/SalemEOL/Salem.jpeg",
          "eTag": "\"78c7521f95bc740a8ddd9f7052c18bc6\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 163802
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.06 PM (1).jpeg",
          "eTag": "\"fded3fbe4a0ff3ba8c97397d4157e81b\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 364729
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.06 PM (2).jpeg",
          "eTag": "\"6f106521026149cd78e5dc273b342a14\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 422000
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.06 PM.jpeg",
          "eTag": "\"fded3fbe4a0ff3ba8c97397d4157e81b\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 364729
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.07 PM (1).jpeg",
          "eTag": "\"8dec76962d4d22201ce42871cc461d81\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 389764
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.07 PM (2).jpeg",
          "eTag": "\"e5b1c6e8f183caba149cbef3418c24e2\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 419662
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.07 PM (3).jpeg",
          "eTag": "\"18c91a0758db8a778c18832d06bd0b91\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 531696
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.07 PM.jpeg",
          "eTag": "\"8dec76962d4d22201ce42871cc461d81\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 389764
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.08 PM (1).jpeg",
          "eTag": "\"cd303b2c994f70082d5a6fc48760f885\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 378119
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.08 PM.jpeg",
          "eTag": "\"053fe654fb24405381a95a57bc94c835\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 249239
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.09 PM (1).jpeg",
          "eTag": "\"f67d40f8d2d38e6801526ec352939f5e\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 394321
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.09 PM.jpeg",
          "eTag": "\"fdb1add2457384bcfde08221390be6aa\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 275535
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.10 PM (1).jpeg",
          "eTag": "\"bee377b2c33b3b46a3e1e2b05e9bd6d3\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 265537
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 1.12.10 PM.jpeg",
          "eTag": "\"ea931a2150b9b7d73a71dc00e3101279\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 399504
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 12.48.23 PM.jpeg",
          "eTag": "\"11136ba4007cbd17e2a3ac02ff8a8f0e\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 145054
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 12.48.25 PM.jpeg",
          "eTag": "\"89e69ce0a16df01d360a64f0a05b34d0\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 200876
        },
        {
          "key": "pictures/SalemEOL/WhatsApp Image 2020-03-23 at 12.48.28 PM.jpeg",
          "eTag": "\"4a8d0ea0a8d9834a5c1924d258cf2b06\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 196760
        }
      ]
    },
    {
      "city": "Satna",
      "images": [
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Busstand_Satna_24.02.2020 (2).jpg",
          "eTag": "\"5f53333cf2e5fde9f92f6bd219da457a\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 170134
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - College_24.02.2020 (2).jpg",
          "eTag": "\"5b6aa5987ced0b34aac0d55aa40aba7a\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 127001
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - College_Satna1_24.02.2020.jpg",
          "eTag": "\"4f633f7ec3424be10ea844c97601d613\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 68625
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - College_Satna3_24.02.2020.jpg",
          "eTag": "\"cc5adcd82e990a74d43f1f5c3fd117c3\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 100764
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Govtoffice_satna_15.02.2020.jpg",
          "eTag": "\"9648f6779abccaf5717b888974db6081\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 224048
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Park_Satna_24.02.2020.jpg",
          "eTag": "\"609ff77465464588085e8fbf0266e704\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 164629
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Satna_24.02 (1).jpg",
          "eTag": "\"6137a6c97f0308c46986cbf0feae3a70\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 142319
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Satna_24.02.2020.jpg",
          "eTag": "\"b22e4939260be99dc131185d3985f221\"",
          "lastModified": "2021-03-02T07:17:48.000Z",
          "size": 145548
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation10_Satna_29.02.2020.jpg",
          "eTag": "\"8812c174d773cbc246f0c6639159db43\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 16227286
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation2_Satna_29.02.2020.jpg",
          "eTag": "\"a8f01fd58146e8c0fecb05035bbc2593\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 12712969
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation5_Satna_29.02.2020.jpg",
          "eTag": "\"c69393589cb9bb4035a273fd63185876\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 14525979
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation6_Satna_29.02.2020.jpg",
          "eTag": "\"3eeb64795f36ff33370dfa960f245811\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 12611482
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation7_Satna_29.02.2020.jpg",
          "eTag": "\"66941c09d01b099b0dd5e7c043d953e2-2\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 18723870
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - Yogameditation9_Satna_29.02.2020.jpg",
          "eTag": "\"ae7e385e1f3a44d0b8cf4169ca0c9376\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 14139685
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - chauraha_satna_15.02.2020.jpg",
          "eTag": "\"78b8e06470502e9a00487332a4ec3ae2\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 179971
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - doortodooradvertisment_Satna_15.02.2020.jpg",
          "eTag": "\"26ec60cefdea0fe2d937e5d8667c7dfc\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 194250
        },
        {
          "key": "pictures/SatnaEOL/chandramani Dwivedi - ratecity images.jpg",
          "eTag": "\"07036b38b86ea122cc2648f60fb880cc\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 74996
        },
        {
          "key": "pictures/SatnaEOL/chandramani dwivedi - College_Satna2.jpg",
          "eTag": "\"80aa14e1919a883ef4b602b4ffd1d8ad\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 112518
        },
        {
          "key": "pictures/SatnaEOL/chandramani dwivedi - Starsmachar_02.02.2020.jpg",
          "eTag": "\"74b036ff6d1880f913c0e72267d3e928\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 124155
        }
      ]
    },
    {
      "city": "Solapur",
      "images": [
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200213-WA0069.jpg",
          "eTag": "\"8b4861d11a8029a26ba66c2d690fd49c\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 150096
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200213-WA0071.jpg",
          "eTag": "\"22fd5cc65f605b7d5dabdee03ec42a45\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 111700
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0056.jpg",
          "eTag": "\"c9714a6b87b94edb5c03f449641fd706\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 77801
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0057.jpg",
          "eTag": "\"096f9b228fa4d1db4e854a981055496f\"",
          "lastModified": "2021-03-02T07:17:49.000Z",
          "size": 68832
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0058.jpg",
          "eTag": "\"29347ba62fbd5504b54e8464fd180d6b\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 81133
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0059.jpg",
          "eTag": "\"9f0eac6e4447cfc7e2bede9acb7288f1\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 96038
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200214-WA0060.jpg",
          "eTag": "\"5b0d10e3a2205ccf4be726bf89af0af1\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 158994
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200217-WA0022.jpg",
          "eTag": "\"ce58c8a5c5b20125a5f894079a5c2f4d\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 87893
        },
        {
          "key": "pictures/SolapurEOL/Tapan Danke - IMG-20200225-WA0005.jpg",
          "eTag": "\"2d3d5f13f343e1c8d6df4f16f2cd406a\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 194653
        }
      ]
    },
    {
      "city": "Surat",
      "images": [
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.49 PM (1).jpeg",
          "eTag": "\"f01bf751a09dfc3cba8a3c592cf77f8a\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 106309
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.52 PM (2).jpeg",
          "eTag": "\"1cc62a93dbb7c5089e31383b8b92d8f9\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 96187
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.52 PM.jpeg",
          "eTag": "\"d3ffb332f403ddef2090f60964cef357\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 98019
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.53 PM (1).jpeg",
          "eTag": "\"906d4896ae9358a36bf52cac3fc540a2\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 91574
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.32.53 PM.jpeg",
          "eTag": "\"a277ed6e3f4b7a038e5b17fb7d4dc582\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 93114
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.28 PM (1).jpeg",
          "eTag": "\"d9545a8fe896eed3f8b44f5b148d463d\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 94502
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.29 PM (1).jpeg",
          "eTag": "\"9524164496167da1cadfb9e0a71a1c52\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 65485
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.29 PM (2).jpeg",
          "eTag": "\"c00c76b1eca25244e47057e8719990af\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 73520
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.29 PM.jpeg",
          "eTag": "\"09477628a1b5dd2ebc83e7eed3b7fe4b\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 78892
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.30 PM (1).jpeg",
          "eTag": "\"10c76f87fd6edac294dee1edd7ede938\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 56177
        },
        {
          "key": "pictures/SuratEOL/WhatsApp Image 2020-03-19 at 12.41.30 PM.jpeg",
          "eTag": "\"a092e17e170157a557a010f565953a82\"",
          "lastModified": "2021-03-02T07:17:50.000Z",
          "size": 101658
        }
      ]
    },
    {
      "city": "Ujjain",
      "images": [
        {
          "key": "pictures/UjjainEOL/61.jfif",
          "eTag": "\"70d12eda14e485e37d8a1f572febf0e5\"",
          "lastModified": "2021-03-02T07:17:51.000Z",
          "size": 157903
        },
        {
          "key": "pictures/UjjainEOL/WhatsApp Image 2020-03-23 at 5.25.33 PM.jpeg",
          "eTag": "\"8480537b4a712867d1d8df0f3f4da411\"",
          "lastModified": "2021-03-02T07:17:51.000Z",
          "size": 345218
        },
        {
          "key": "pictures/UjjainEOL/ujjain_school_eol_8.jpg",
          "eTag": "\"523ebf06ea13db480020a56ebd801951\"",
          "lastModified": "2021-03-02T07:17:51.000Z",
          "size": 241916
        }
      ]
    }
];

export default fieldimages;