import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {infoColor, warningColor,dangerColor,successColor} from "../assets/jss/livability.js";


// core components

import GridItem from "./GridItem.js";
import GridContainer from "./GridContainer.js";
import Card from "./Card.js";
import CardHeader from "./CardHeader.js";
import CardBody from './CardBody.js';
import CardFooter from "./CardFooter.js";
import CardAvatar from "./CardAvatar.js";
import Image from "./Image.js";

//import SwipeableTextMobileStepper from './SwipeableTextMobileStepper.js';
import ImageCarousel from './ImageCarousel.js';

import {eol_over_1m, eol_under_1m} from '../json/eol.js';
import {mpi_over_1m, mpi_under_1m} from '../json/mpi.js';
import {cities} from '../json/cities.js';

import IconButton from "@material-ui/core/IconButton";
import Next from '@material-ui/icons/KeyboardArrowRight';
import Prev from '@material-ui/icons/KeyboardArrowLeft';

//import { Storage } from 'aws-amplify';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  cardTitleInfo: {
    color: "#00aac1",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function CityProfile(props) {
  const classes = useStyles();
  
  const cityRecord = cities.find(ct => ct.city_lowercase === props.city);
  const [cityEOLRecord, setCityEOLRecord] = React.useState(null);
  const [cityMPIRecord, setCityMPIRecord] = React.useState(null);

  const CitiesNoPic = [
                        "Navi Mumbai",   "Greater Mumbai",  "Jodhpur",  "Hyderabad",  "Ghaziabad",  "Meerut",  "Vasai Virar",  "Vijayawada",  "Dhanbad",
                        "Gurugram",   "Karimnagar",  "Erode",  "Rae Bareli",  "Dindigul",  "Rampur",  "South Delhi",  "East Delhi",  "North Delhi"]

  let cityName = "";

  React.useEffect(() => {
    const handleKeyDown = event => {
     const { key } = event;

     if(key === "Backspace"){
       props.close();
     }
     else if(key === "ArrowRight"){
       props.next();
     }
     else if(key === "ArrowLeft"){
      props.prev();
    }
    else{
      return;
    }
   };

   document.addEventListener('keydown', handleKeyDown)

   return () => {
     document.removeEventListener('keydown', handleKeyDown)
   }
 }, [props])


  React.useEffect(() => {
        if(cityRecord && cityRecord.city){
          if(cityRecord.millionplus){
            if(cityRecord.eol){
              setCityEOLRecord(eol_over_1m.find(record => record.city === cityRecord.city));
            }

            if(cityRecord.mpi){
              setCityMPIRecord(mpi_over_1m.find(record => record.city === cityRecord.city));
            }
          }
          else{
            if(cityRecord.eol){
              setCityEOLRecord(eol_under_1m.find(record => record.city === cityRecord.city));
            }
            if(cityRecord.mpi){
              setCityMPIRecord(mpi_under_1m.find(record => record.city === cityRecord.city));
            }  
          }
        }
  }, [cityRecord]); 

  function truncateFloat(n){
    if(n && n !== 0){
      return n.toFixed(2);
    }
    return "0.00";
  }

  function getColorCodedAvatar(rank, score, big){
    const colorcode = score > 50 ? successColor[0] : score > 30 ? warningColor[0] : dangerColor[0];
    const size = big ? '48px' : '32px';
    return (<Avatar style={{width:size, height:size, backgroundColor:colorcode}}>{rank}</Avatar>)
  }

  const logo = cityRecord ? "https://livabilitystore175634-prod.s3.amazonaws.com/public/logos/"+cityRecord.logo :
                            "https://dummyimage.com/72x72/00aac1/ffffff&text=Not found";

  const factSheet = cityRecord ? "https://livabilitystore175634-prod.s3.amazonaws.com/public/factsheets/"+cityRecord.city.replaceAll(' ', '-')+"_Updated.pdf" : null;
  
  const citypic = cityRecord ? 
                  CitiesNoPic.includes(cityRecord.city) ?  'https://livabilitystore175634-prod.s3.amazonaws.com/public/citypics/'+cityRecord.city.replaceAll(' ', '-')+".jpg" :
                  "https://smartcities.data.gov.in/sites/default/files/"+cityRecord.image : null;

  return (
    <div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
          <Card profile >
            <CardAvatar profile>
                <img src={logo} alt="citylogo" align="center" />
            </CardAvatar>
            <CardBody>
              <div style={{display:'flex', flexDirection:'column', alignItems:'space-between'}}>
                <div>
                  <Image 
                    src={citypic ? citypic : "https://dummyimage.com/569x595/ffffff/00aac1&text=Image%20not%20available"} 
                    fallbackSrc="https://dummyimage.com/569x595/ffffff/00aac1&text=Image%20not%20available" 
                    alt={cityName} 
                    width="100%" 
                    key={cityRecord ? cityRecord.image : "blank"}
                  />
                </div>
                <div style={{display:'flex', flexDirection:'row', width:'100%', alignItems:"center", justifyContent:'space-between'}}>
                  <div><IconButton onClick={props.prev}><Prev style={{color:infoColor[0], fontSize:'36px'}}/></IconButton></div>
                  <div><h4 className={classes.cardTitleInfo}>{cityRecord ? cityRecord.city : cityMPIRecord ? cityMPIRecord.city : ""}</h4></div>
                  <div><IconButton onClick={props.next}><Next style={{color:infoColor[0], fontSize:'36px'}}/></IconButton></div>
                </div>
                {/* <div style={{display:'flex', flexDirection: 'row', justifyContent:'center', alignItems:'center'}}>   */}
                { factSheet ? 
                   <span>
                   <Link href={factSheet} style={{color: infoColor[0], fontSize: 'medium'}} target="_blank" rel="noopener noreferrer">
                     <i className="fa fa-file-pdf-o" style={{fontSize: '24px', color:'#F40F02', paddingRight:'15px'}}/>City Fact Sheet
                   </Link>
                 </span>
                 : null
                }
                 
                {/* </div> */}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      
        <GridItem xs={12} sm={12} md={8}>
          <GridContainer direction="column">
            <GridItem>
                <Card >
                <CardHeader color="info">
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <div style={{fontSize:'large', fontWeight: 'bold'}}>
                     Ease of living
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                <Grid container spacing={3} >
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Ease of living (Overall) </b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityEOLRecord ? truncateFloat(cityEOLRecord.score) : ""} </Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                            {cityEOLRecord ? getColorCodedAvatar(cityEOLRecord.rank, cityEOLRecord.score, false) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Quality of life</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityEOLRecord ? truncateFloat(cityEOLRecord.qol_score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                            {cityEOLRecord ? getColorCodedAvatar(cityEOLRecord.qol_rank, cityEOLRecord.qol_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Economic ability</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityEOLRecord ? truncateFloat(cityEOLRecord.ea_score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                            {cityEOLRecord ? getColorCodedAvatar(cityEOLRecord.ea_rank, cityEOLRecord.ea_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Sustainability</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityEOLRecord ?  truncateFloat(cityEOLRecord.s_score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                            {cityEOLRecord ? getColorCodedAvatar(cityEOLRecord.s_rank, cityEOLRecord.s_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Citizen perception</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityEOLRecord ? truncateFloat(cityEOLRecord.cp_score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                            {cityEOLRecord ? getColorCodedAvatar(cityEOLRecord.cp_rank, cityEOLRecord.cp_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                  </Grid> {/* Container close */}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem>
                <Card>
                <CardHeader color="info">
                <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                  <div style={{fontSize:'large', fontWeight: 'bold'}}>
                     Municipal performance
                    </div>
                  </div>
                </CardHeader>
                <CardBody >
                      <Grid container spacing={3} >
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Municipal performance (Overall)</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityMPIRecord ? truncateFloat(cityMPIRecord.score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                            {cityMPIRecord ? getColorCodedAvatar(cityMPIRecord.rank, cityMPIRecord.score, false) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Services</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityMPIRecord ? truncateFloat(cityMPIRecord.s_score) : ""} </Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                            {cityMPIRecord ? getColorCodedAvatar(cityMPIRecord.s_rank, cityMPIRecord.s_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Finance</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityMPIRecord ? truncateFloat(cityMPIRecord.f_score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                            {cityMPIRecord ? getColorCodedAvatar(cityMPIRecord.f_rank, cityMPIRecord.f_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Technology</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityMPIRecord ? truncateFloat(cityMPIRecord.t_score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                              {cityMPIRecord ? getColorCodedAvatar(cityMPIRecord.t_rank, cityMPIRecord.t_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Planning</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityMPIRecord ? truncateFloat(cityMPIRecord.p_score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                              {cityMPIRecord ? getColorCodedAvatar(cityMPIRecord.p_rank, cityMPIRecord.p_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{flexGrow:1}}> 
                      <Paper style={{backgroundColor:'rgba(238, 238, 238, 0.5)'}} >
                        <Grid container spacing={1} style={{padding:'20px'}} justify="center" alignItems="flex-start">
                          <Grid item xs zeroMinWidth style={{flex: 1}} >
                            <Typography noWrap><b>Governance</b></Typography>
                            <div style={{display:'flex', direction:'row', justifyContent:'flex-start', alignItems:'center', margin: '-2px'  }}>
                              <Typography noWrap>Score: {cityMPIRecord ? truncateFloat(cityMPIRecord.g_score) : ""}</Typography> 
                            </div>
                          </Grid>
                          <Grid item style={{height:'100%', alignSelf:'flex-start'}} >
                              {cityMPIRecord ? getColorCodedAvatar(cityMPIRecord.g_rank, cityMPIRecord.g_score) : "NA"}
                          </Grid>
                        </Grid>               
                      </Paper>
                      </Grid>
                  </Grid> {/* Container close */}
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem>
             <Card style={{marginBottom: 0, marginTop: '10px'}}>
                <CardHeader color="info">
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <div>
                    </div>
                    <div style={{fontSize:'large', fontWeight: 'bold'}}>
                     Images
                    </div>
                  </div>
                </CardHeader>
               <CardBody style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                   <ImageCarousel id={cityRecord?.id} />
                </CardBody>
              </Card> 
            </GridItem> 
            <GridItem style={{marginTop: '30px'}}>
            <Card>
                <CardHeader color="info">
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <div>
                    </div>
                    <div style={{fontSize:'large', fontWeight: 'bold'}}>
                     Videos
                    </div>
                  </div>
                </CardHeader>
                <CardBody style={{display:'flex', justifyContent:'center'}}>
                  <ImageCarousel id={cityRecord?.id} type="video" />
                </CardBody>
              </Card>
            </GridItem>           
          </GridContainer>
        </GridItem>
        </GridContainer>
    </div>
  );
}
