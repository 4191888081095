import Dashboard from "./Components/Dashboard.js";
import Home from "./Components/Home.js";

//import ComingSoon from "./Components/ComingSoon.js";
import Resources from "./Components/Resources.js";
import PhotoGallery from "./Components/PhotoGallery.js";

import CitizenPerception from "./Components/CitizenPerception.js";
import Contact from "./Components/Contact.js";

const dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/cp",
    name: "CitizenSpeak",
    component: CitizenPerception,
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: PhotoGallery,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  }
];


export default dashboardRoutes;
