/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";


import styles from "../assets/jss/livability/components/menubarStyle.js";

const useStyles = makeStyles(styles);

export default function Menubar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, routes } = props;

  var links = (
    <List className={classes.list} >
      {routes.map((prop, key) => {
        var listItemClasses;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        });
        
        const blueFontClasses = classNames({
          [" " + classes.blueFont]: activeRoute(prop.path) 
        });

        return (
          <NavLink
            to={prop.path}
            className={classes.item}
            activeClassName="active"
            onClick={props.closeDrawerToggle} 
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, blueFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  return (
    <div style={{display:'flex', flexDirection:'row', width:'78%', justifyContent:'space-between'}}>
          <div className={classes.sidebarWrapper1} style={{justifyContent:'flex-start', alignItems:'center', paddingLeft:'10px'}}>
            <div style={{fontFamily: "Poppins, Roboto", fontSize: 'large', color:'white', fontWeight:'bold', border:'2px solid white', padding:'3px'}}>
              City Rankings 2020
            </div>
          </div>
          <div className={classes.sidebarWrapper}>{links}</div>
    </div>
  );
}

Menubar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
