// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "ivability-20210207175955-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dedhdgib10q0e.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:97cce3f5-e991-4350-8b5f-e943f808c350",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XslwDGMDj",
    "aws_user_pools_web_client_id": "31urhmqk2v7bo3di8n13qgtr3j",
    "oauth": {},
    "aws_user_files_s3_bucket": "livabilitystore175634-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
