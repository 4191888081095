import React from "react";
import VideoBackground from './VideoBackground.js'
import GridItem from "./GridItem.js";
import GridContainer from "./GridContainer.js";
import Card from "./Card.js";
import CardHeader from "./CardHeader.js";
import CardBody from './CardBody.js';
import CardAvatar from "./CardAvatar.js";
import Footer from './Footer.js';
import ReactPlayer from 'react-player';
import {infoColor} from "../assets/jss/livability.js";

import minister from "../assets/img/minister.jpg";
import secretary from "../assets/img/secretary.jpg";

import Hidden from "@material-ui/core/Hidden";


import '../assets/css/btn.css'
 
export default function Home(props){
    function gotoDashboard(){
        window.location.href = '/dashboard';
    }
    return (
            <div>
                <VideoBackground/>
                <Hidden smDown implementation="css">
                <div>
                    <div style={{fontFamily: "Poppins, Roboto", width:'40%', minHeight:'30vh', backgroundColor:'black',  paddingBottom: '10px', color: 'white', opacity: '0.8', textAlign:'center', position:'absolute', zIndex:'10', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <div style={{fontSize: '44px', lineHeight:'44px', fontWeight:'700', padding: '10px', paddingBottom:'20px'}}>City Rankings 2020</div>
                        <div style={{fontSize: '24px', lineHeight:'24px', fontWeight:'400', paddingBottom: '20px', textAlign:'center'}}>Find out India's most livable & best performing cities</div>
                        <div><button className="knowmore" onClick={gotoDashboard} >View Rankings</button></div>
                    </div>
                </div>
                </Hidden>
                <Hidden mdUp implementation="css">
                <div>
                    <div style={{fontFamily: "Poppins, Roboto", width:'100%', minHeight:'30vh', backgroundColor:'black', color: 'white', opacity: '0.8', textAlign:'center', position:'absolute', zIndex:'10', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display:'flex', flexDirection:'column', justifyContent:'center'}}>
{/*                         <div style={{fontSize: '24px', lineHeight:'24px', fontWeight:'700', padding: '10px', paddingBottom:'20px'}}>City Rankings 2020</div> */}
                        <div style={{fontSize: '20px', lineHeight:'20px', fontWeight:'400', paddingBottom: '20px', textAlign:'center'}}>Find out India's most livable & best performing cities</div>
                        <div><button className="knowmore" onClick={gotoDashboard} >View Rankings</button></div>
                    </div>
                </div>
                </Hidden>
 
                <div style={{width:'100vw', backgroundColor: 'white', position:'relative', top: '100vh', marginTop: '-18vh', marginLeft: '-30px'}}>
                <GridContainer style={{paddingTop: '100px', paddingLeft:'20px', paddingRight:'20px'}}>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card>
                            <CardHeader style={{fontFamily: "Poppins, Roboto", fontSize: 'x-large', color:infoColor[0], textAlign:'center'}}>
                                Launch Video
                            </CardHeader>
                            <CardBody style={{justifyContent:'center'}}>
                                <ReactPlayer width="100%" url="https://youtu.be/MYa7FhzJxJU" key="launchvideo" />
                            </CardBody>
                        </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        <Card profile >
                            <CardAvatar profile>
                                <img src={minister} alt="Face" align="center" />
                            </CardAvatar>
                            <CardBody profile>
                            <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                                <div style={{fontSize: 'large', color:infoColor[0], textAlign:'center', fontWeight:'bold'}}>
                                    Hardeep S. Puri 
                                </div>
                                <div style={{fontSize: 'medium', color:'black', textAlign:'center', fontWeight:'bold'}}>
                                    Minister of State (I/C) - Housing and Urban Affairs 
                                </div>
                                <div style={{fontSize: 'medium', color:'gray', textAlign:'justify'}}>
                                <p>
                                Under the leadership of the Hon’ble Prime Minister, Shri Narendra Modi, the Indian government is committed to the mission of transforming the urban landscape to improve the liveability of Indian cities. The Ease of Living Index and the Municipal Performance Index are being launched as valuable tools for city administration to identify the gaps in their journey towards these goals and address them with suitable strategies. 
                                </p>
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                        <Card profile >
                            <CardAvatar profile>
                                <img src={secretary} alt="Face" align="center" />
                            </CardAvatar>
                            <CardBody profile>
                            <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                                <div style={{fontSize: 'large', color:infoColor[0], textAlign:'center', fontWeight:'bold'}}>
                                    Durga Shanker Mishra 
                                </div>
                                <div style={{fontSize: 'medium', color:'black', textAlign:'center', fontWeight:'bold'}}>
                                    Secretary - Ministry of Housing and Urban Affairs
                                </div>
                                <div style={{fontSize: 'medium', color:'gray', textAlign:'justify'}}>
                                <p>
                                The learnings from the Ease of Living Index and Municipal Performance Index will enable cities to build practices that improve the quality of urban living in India while offering economic growth and sustainability in the long run. It will also enhance the progress of Indian cities towards the Sustainable Development Goals by identifying city-specific strengths and challenges. &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </p>
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                        </GridItem>
                </GridContainer>
                <div style={{textAlign: 'center'}}><Footer/></div>
                </div>
            </div>  
        ) 
}