import React from "react";
import Hidden from "@material-ui/core/Hidden";
import {infoColor} from "../assets/jss/livability.js";
import Link from '@material-ui/core/Link';
import mohuaLogo from '../assets/img/mohuaLogo.png'

export default function Contact(props){

    return (
        <div>
        <Hidden smDown implementation="css">
            <div style={{fontFamily: "Poppins, Roboto", width:'70%', borderRadius: '20px', backgroundColor:'white', color: 'gray', position:'absolute', zIndex:'10', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',  padding:'10px', display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                <div style={{display:'flex', flexDirection:'column', flex: 1, textAlign:'center', borderRight:'1px solid gray'}} >
                    <div><img src={mohuaLogo} alt="MoHUA" height="150px"/></div>
                    <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '10px', textAlign:'center'}}>Nirman Bhawan, Maulana Azad Road</div>
                    <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '30px', textAlign:'center'}}> New Delhi-110011</div>
                    <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '10px', textAlign:'center'}}>Email: smartcity-mohua@gov.in</div>
                    <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '50px', textAlign:'center'}}>
                        <Link href="https://smartcities.gov.in" style={{color:infoColor[0]}} target="_blank" rel="noopener noreferrer" >
                            smartcities.gov.in
                        </Link>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'column', flex: 1, textAlign:'center',}}>
                    <div><img src="https://competitiveness.in/wp-content/uploads/2017/02/IFCLogo.jpg" alt="IFC" height="150px"/></div>
                    <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '10px', textAlign:'center'}}>U24/8, DLF Phase - 3</div>
                    <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '30px', textAlign:'center'}}>Gurgaon - 122002</div>
                    <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '10px', textAlign:'center'}}>Email: info@competitiveness.in</div>
                    <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '50px', textAlign:'center'}}>
                        <Link href="https://competitiveness.in/" style={{color:infoColor[0]}} target="_blank" rel="noopener noreferrer" >
                            www.competitiveness.in
                        </Link>
                    </div>
                </div>
            </div>
        </Hidden>

        <Hidden mdUp implementation="css">
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', width:'300px'}}>
            <div style={{fontFamily: "Poppins, Roboto", borderRadius: '20px', backgroundColor:'white', color: 'gray', textAlign:'center', display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <div><img src={mohuaLogo} alt="MoHUA" width="200px"/></div>
                <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '10px', textAlign:'center'}}>Nirman Bhawan, Maulana Azad Road</div>
                <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '30px', textAlign:'center'}}> New Delhi-110011</div>
                <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '10px', textAlign:'center'}}>Email: smartcity-mohua@gov.in</div>
                <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '30px', textAlign:'center'}}>
                    <Link href="https://smartcities.gov.in" style={{color:infoColor[0]}} target="_blank" rel="noopener noreferrer" >
                        smartcities.gov.in
                    </Link>
                </div>
                <hr style={{backgroundColor:'gray', width:'80%'}} />
                <div><img src="https://competitiveness.in/wp-content/uploads/2017/02/IFCLogo.jpg" alt="IFC" width="200px"/></div>
                <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '10px', textAlign:'center'}}>U24/8, DLF Phase - 3</div>
                <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '30px', textAlign:'center'}}>Gurgaon - 122002</div>
                <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '10px', textAlign:'center'}}>Email: info@competitiveness.in</div>
                <div style={{fontSize: 'large', lineHeight:'24px', fontWeight:'400', paddingBottom: '50px', textAlign:'center'}}>
                    <Link href="https://competitiveness.in/" style={{color:infoColor[0]}} target="_blank" rel="noopener noreferrer" >
                        www.competitiveness.in
                    </Link>
                </div>
            </div>
        </div>
        </Hidden>
        </div>
    );
}