import React from 'react';

import '../assets/css/videobg.css'

export default function VideoBackground(){
    return (
    <div className="vimeo-wrapper">
        <iframe title="intro" src="https://player.vimeo.com/video/518502985?background=1&autoplay=1&loop=1&byline=0&title=0"
           frameBorder={0} allowFullScreen={true}></iframe>
    </div>
    )
}