import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';

import styles from "../assets/jss/livability/components/tableStyle.js";
import taskStyles from "../assets/jss/livability/components/tasksStyle.js";
import {infoColor, warningColor,dangerColor,successColor} from "../assets/jss/livability.js";


import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import LaunchIcon from '@material-ui/icons/CallMade';

import ResponsiveDialog from './ResponsiveDialog.js'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = (pillar, indicator) => {
  
  const rankfield = indicator !== "" ? indicator+ 'rank' : pillar + indicator + 'rank';
  const scorefield = indicator !== "" ? indicator+ 'score' : pillar + indicator + 'score';
  
    return [
    { id: rankfield, numeric: true, disablePadding: true, label: 'Rank' },
    { id: 'city', numeric: false, disablePadding: false, label: 'City' },
  /*   { id: 'state', numeric: false, disablePadding: false, label: 'State' }, */
    { id: scorefield, numeric: true, disablePadding: false, label: 'Score' },
  ];
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,tableHeaderColor } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
      <TableRow className={classes.tableHeadRow}>
        <TableCell padding="checkbox">
          <Checkbox
            //indeterminate={numSelected > 0 && numSelected < rowCount}
            indeterminate={numSelected > 0 && numSelected !== rowCount}
            indeterminateIcon={<IndeterminateCheckBoxIcon className={classes.indeterminateIcon} />}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            key="selectAll"
            classes={{
              checked: classes.checked,
              root: classes.root
            }}
          />
        </TableCell>
        {headCells(props.pillar, props.indicator).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id.indexOf("rank") !== -1 ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  pillar: PropTypes.string,
};

const useStyles = makeStyles(styles);
const useTaskStyles = makeStyles(taskStyles);

export default function EnhancedTable(props) {

  const rankfield = props.indicator !== "" ? props.indicator + 'rank' : props.pillar + props.indicator + 'rank';
  const scorefield = props.indicator !== "" ? props.indicator + 'score' : props.pillar + props.indicator + 'score';

  const classes = useStyles();
  const taskClasses = useTaskStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(rankfield);

  const [page, setPage] = React.useState(0);
  const dense = false;
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const {tableHeaderColor } = props;

  const [indx,setIndx] = React.useState(-1);
  const [list, setList] = React.useState([]);

  const hideCity = () => {
    setList([]);
    setIndx(-1);
  };

  const showCity = (e, i, l) => {
    e.preventDefault();
    setIndx(i);
    setList(l);
  };

  const prev = () => {
    if(indx > 0){
      setIndx(indx-1);
    }
    else{
      setIndx(props.ranking.length - 1);
    }
  }

  const next = () => {
    if(indx < props.ranking.length - 1 ){
      setIndx(indx+1);
    }
    else{
      setIndx(0);
    }
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    props.selectAll(event.target.checked);
  };

  const handleClick = (event, id) => {
      props.changeSelection(id, event.target.checked);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.ranking.length - page * rowsPerPage);
  const sortedSet = stableSort(props.ranking,  getComparator(order, orderBy));

  return (
    <div className={classes.root} >
      <Paper className={classes.paper} style={{backgroundColor:'white'}}>
        <TableContainer>
          <Table
            className={classes.table}
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              classes={taskClasses}
              tableHeaderColor={tableHeaderColor}
              numSelected={props.ranking.filter(r => !r.unselected || !r.unselected).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.ranking.length}
              pillar={props.pillar}
              indicator={props.indicator}
            />
            <TableBody>
              {sortedSet
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  const isItemSelected = !row.unselected || !row.unselected;
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const colorcode = row[scorefield] > 50 ? successColor : row[scorefield] > 30 ? warningColor : dangerColor;
                  
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={classes.tableBodyRow}
                      style={isItemSelected ? {backgroundColor:'white'} : {backgroundColor:'lightgray'} }
                    >
                      <TableCell padding="checkbox" key="chk">
                        <Checkbox
                          checked={isItemSelected}
                          name={row.id}
                          classes={{
                            checked: taskClasses.checked,
                            root: taskClasses.root
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" key="rnk">
                        <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                          <Avatar style={{width:'32px', height:'32px', backgroundColor:colorcode[0]}}>{row[rankfield]}</Avatar>
                        </div>
                      </TableCell>
                      <TableCell style={{align:'left'}} key="city">
                        <div style={{display:'flex', flexDirection:'row', alignItems:'flex-end', justifyContent:'flex-start'}}>
                          <div>
                            <Link href="#" style={{color:infoColor[0]}} onClick={(e)=>showCity(e, (page * rowsPerPage)+index, sortedSet)} >
                                <span style={{whiteSpace: "nowrap"}}>
                                  {row.city}
                                </span>
                            </Link>
                          </div>
                          <div>
                          <Link href="#" style={{color:infoColor[0], paddingLeft:'10px'}} onClick={(e)=>showCity(e, (page * rowsPerPage)+index, sortedSet )}>
                              <LaunchIcon style={{fontSize:"12", color:infoColor[0]}}/>
                            </Link>

                          </div>
                        </div>
                        
                      </TableCell>
                      <TableCell align="right" key="score">{row[scorefield] && row[scorefield] !== 0 ? (row[scorefield]).toFixed(2) : "0.00"}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={props.ranking.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <ResponsiveDialog city={indx > -1 && list !== undefined ? list[indx] : null} next={next} prev={prev} handleClose={hideCity}  />
    </div>
  );
}