import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import {images} from '../json/images.js';

function ImageCarousel(props){
    if(props.type === "video"){
        if(props.id){
            const vidRecord = images.find( video => video.id.trim() === props.id);
            if(vidRecord){
                const videos = [ vidRecord.video1,  vidRecord.video2,  vidRecord.video3,  vidRecord.video4,  vidRecord.video5 ];
                const filteredVideos = videos.filter(video => video.trim() !== "" && (video.indexOf("youtu.be") !== -1 || video.indexOf("youtube") !== -1 || video.indexOf("vimeo") !== -1));
                if(filteredVideos.length > 0){
                    const videoCarousal = filteredVideos.map((url, i) => {
                        return (
                            <ReactPlayer width="100%" url={url} key={"vid-"+i} height="60vh" />
                        );
                    });

                    return (
                        <Carousel 
                            showArrows={true} 
                            autoPlay={false} 
                            dynamicHeight={false} 
                            showThumbs={false}
                            stopOnHover={false}
                        >
                            {videoCarousal}
                        </Carousel>
                    );

                }
                
            }
        }
        return (<div>No videos available</div>)
    }
    else { //Default type is "image"
        if(props.id){
            const imgRecord = images.find( image => image.id.trim() === props.id);
            if(imgRecord && imgRecord.images && imgRecord.images !== ""){
                const imgArray = imgRecord.images.split(",");
                if(imgArray && imgArray.length > 0){
                    const imgCarousal = imgArray.map((imge, i) => {
                        return (
                            <div key={i} style={{maxHeight: '70vh'}} >
                                <img src={imge.replaceAll("open?id=", "uc?id=")} alt={"img-"+i}  />
                            </div>
                        )
                    });
        
                    if(imgCarousal && imgCarousal.length > 0){
                        return (
                            <Carousel 
                                showArrows={true} 
                                autoPlay={true} 
                                dynamicHeight={false} 
                                interval={5000} 
                                infiniteLoop={true} 
                                showThumbs={false}
                                stopOnHover={false}
                                style={{maxHeight:'90vh'}}
                            >
                                {imgCarousal}
                            </Carousel>
                        );
                    }
                }
            }    
        }
        
        return (<div>No images available</div>)
    }
}

export default ImageCarousel;
