import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Face";

// core components
import GridItem from "./GridItem.js";
import GridContainer from "./GridContainer.js";
import Card from "./Card.js";
import CardHeader from "./CardHeader.js";
import CardIcon from "./CardIcon.js";
import CardFooter from "./CardFooter.js";
import EnhancedTable from "./EnhancedTable.js";
//import Hidden from "@material-ui/core/Hidden";
import {eol_over_1m, eol_under_1m} from '../json/eol.js';
import {mpi_over_1m, mpi_under_1m} from '../json/mpi.js';

import Map from './Map.js';
import BarChart from './BarChart.js';
import Selectors from './Selectors.js';

import {eol_stats, mpi_stats}  from '../json/stats.js';
import styles from "../assets/jss/livability/views/dashboardStyle.js";
import Legend from './Legend.js'

const useStyles = makeStyles(styles);
let viewport = { width: '100%', height: '100%', latitude: 22.551293, longitude: 78.480786, zoom: 3.5  };
let rankings = { 
                "eol" : { 
                  "om" : eol_over_1m, 
                  "um" : eol_under_1m
                }, 
                "mpi": {
                  "om" : mpi_over_1m, 
                  "um" : mpi_under_1m
                }
              };

  let stats = {"eol" : eol_stats, "mpi" : mpi_stats}; 


export default function Dashboard(props) {
  const classes = useStyles();
  const [filters, setFilters] = React.useState({index: 'eol', category: 'om', pillar: "", indicator: "", state: "", display: "map"});
  const [ranks, setRanks] = React.useState(eol_over_1m);

  const changeFilters = (event, value, name) => {

    if(name === "state"){
      if(value === null || value === ""){
        setRanks(rankings[filters.index][filters.category]);
      }
      else{
        setRanks(rankings[filters.index][filters.category].filter(n => n.state.toLowerCase() === value.toLowerCase()));
      }
      setFilters({...filters, "state" : value});
    }
    else if(name === "pillar"){
      setFilters({...filters, "pillar" : value, "indicator" : ""});
    }
    else if(name === "indicator"){
      setFilters({...filters, "indicator" : value});
    }
    else if(name === "display"){
      setFilters({...filters, "display" : value});
    }
    else{
      let newRanks = null;
      if(name === "index"){
        newRanks = rankings[value][filters.category];
        setFilters({...filters, [name] : value, "pillar" : "", "indicator" : ""});
      }
      else if(name === "category"){
        newRanks = rankings[filters.index][value];
        setFilters({...filters, [name] : value});
      }
      if(filters.state !== ""){
        newRanks = newRanks.filter(n => n.state.toLowerCase() === filters.state.toLowerCase());
      }
      setRanks(newRanks);
    }

  };

  const changeSelection = (id, flag) => {
    const newRanks = ranks.map(obj => {
      if(obj.id === id){
        return ({ ...obj, unselected: !flag });
      }
      else{
        return obj;
      }
    });

    setRanks(newRanks);
  };

  const selectAll = (flag) => {
    setRanks(ranks.map(obj => {
        return ({ ...obj, unselected: !flag });
    }));
  };

  return (

    <div >
     <GridContainer>
    <GridItem xs={12} sm={6} md={3}>
      <Card style={{marginBottom:'5px'}}>
        <CardHeader color="info" icon>
          <CardIcon color="primary">
            <Icon>location_city</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Number of cities</p>
          <h3 className={classes.cardTitle}>
            {stats[filters.index] ? stats[filters.index]?.count : ""}
          </h3>
        </CardHeader>
         <CardFooter stats>
          <div className={classes.stats}>
          
          </div>
        </CardFooter> 
      </Card>
    </GridItem>
    <GridItem xs={12} sm={6} md={3} >
      <Card style={{marginBottom:'5px'}}>
        <CardHeader color="info" icon>
          <CardIcon color="rose">
          <Icon>insights</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Average city score</p>
          <h3 className={classes.cardTitle}>{stats[filters.index] ? stats[filters.index][filters.pillar] : ""}</h3>
        </CardHeader>
       <CardFooter stats>
          <div className={classes.stats}>
          </div>
        </CardFooter>
      </Card>
    </GridItem>
    <GridItem xs={12} sm={6} md={3}>
      <Card style={{marginBottom:'5px'}}>
        <CardHeader color="info" icon>
          <CardIcon color="primary">
            <Store />
          </CardIcon>
          <p className={classes.cardCategory}>Citizen feedback</p>
          <h3 className={classes.cardTitle}>3.2 million</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
          </div>
        </CardFooter>
      </Card>
    </GridItem>
    <GridItem xs={12} sm={6} md={3}>
      <Card style={{marginBottom:'5px'}}>
        <CardHeader color="info" icon>
          <CardIcon color="primary">
            <Icon>thumb_up</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Social media hits</p>
          <h3 className={classes.cardTitle}>1.3 million</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  </GridContainer> 
    <GridContainer>
    <GridItem xs={12} sm={12} md={6} style={{height:'100%'}}>
        <div style={{display:'flex', flexDirection:'column'}}>
          <Card style={{marginBottom:'5px'}}>
          <CardHeader style={{width:'100%'}}>
            <Selectors filters={filters} changeFilters={changeFilters} />
          </CardHeader>
          </Card>

          <div>
          <Card>
          <CardHeader>  
            <EnhancedTable 
              key={filters.index+filters.pillar+filters.indicator+filters.state}
              tableHeaderColor="warning" 
              ranking={ranks}
              pillar={filters.pillar}
              indicator={filters.indicator}
              changeSelection={changeSelection}
              selectAll={selectAll}
            />
            <Legend/>
          </CardHeader>
          </Card>
          </div>
        </div>
          
    </GridItem>
       <GridItem xs={12} sm={12} md={6} style={{height: "100%"}}>
       <Card><CardHeader>  
         { filters.display === "map" ?
            <Map viewport={viewport} ranking={ranks} pillar={filters.pillar} indicator={filters.indicator} /> :
             <BarChart ranking={ranks} pillar={filters.pillar} indicator={filters.indicator} />
         }
         </CardHeader> </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
  
}
