import React from 'react';
import {infoColor} from "../assets/jss/livability.js";


export default function Footer(){
    return (
        <div style={{backgroundColor:infoColor[0], color:'white', display:'flex', flexDirection:'row', justifyContent:'space-around', fontFamily: "Poppins, Roboto", fontSize: 'small', margin:'5px'}}>
            © 2020 Ministry of Housing and Urban Affairs, Government of India. All Rights Reserved.
        </div>
    )
}